import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import { Dropdown, Tooltip } from 'antd';
import Menu from 'antd/lib/menu';
import clsx from 'clsx';
import AddRemoveClusterServicePopconfirm from '../AddRemoveClusterServicePopconfirm/AddRemoveClusterServicePopconfirm';
import useServiceAction from '../ClusterServicesControlActions/useServiceAction';
import { SessionContext } from 'auth/SessionProvider';
import { ClusterAction, clusterScalingNode, ServiceToRestart } from 'types/cluster';
import './ClusterServiceTableHeaderOptions.less';

type ClusterServiceTableHeaderOptionsProps = {
  canControl: boolean;
  canScale: boolean;
  isClusterConnected: boolean;
  hasServiceStatusError: boolean;
  instanceId: string;
  serviceNode: ServiceToRestart;
  serviceType: 'analytics' | 'loader' | 'sqli';
  clusterName: string;
  minNodesCount: number;
  maxNodesCount: number;
  clusterScalingNodes: clusterScalingNode[];
};
function ClusterServiceTableHeaderOptions({
  canControl,
  canScale,
  isClusterConnected,
  hasServiceStatusError,
  instanceId,
  serviceNode,
  serviceType,
  clusterName,
  minNodesCount,
  maxNodesCount,
  clusterScalingNodes,
}: ClusterServiceTableHeaderOptionsProps) {
  const { user } = useContext(SessionContext);
  const isTrialUser = user?.role?.id === 1;

  function handleDDlVisibleChange(visible: boolean) {
    setIsDdlVisible(visible);
  }
  const [isDdlVisible, setIsDdlVisible] = useState(false);
  const [addConfirmLoading, setAddConfirmLoading] = useState(false);
  const [deleteConfirmLoading, setDeleteConfirmLoading] = useState(false);

  const instanceServices = user.instanceServices?.find((srvc) => srvc.instanceId === instanceId);
  const serviceCount =
    (serviceType === 'analytics'
      ? instanceServices?.analyticsNodes
      : serviceType === 'loader'
      ? instanceServices?.loaderNodes
      : 0) || 0;
  const isAddingDisabled =
    isTrialUser || !isClusterConnected || hasServiceStatusError || serviceCount >= maxNodesCount || addConfirmLoading;

  const { triggeredAction, handleServiceRestartorStop, isChangingServiceState } = useServiceAction({
    instanceId,
    serviceNode,
  });

  const isStopBtnDisabled =
    ([ClusterAction.STOP, ClusterAction.RESTART].includes(triggeredAction!) && isChangingServiceState) ||
    deleteConfirmLoading;
  const isRestartBtnDisabled =
    ([ClusterAction.STOP, ClusterAction.RESTART].includes(triggeredAction!) && isChangingServiceState) ||
    deleteConfirmLoading;
  const isStartBtnDisabled =
    ([ClusterAction.START, ClusterAction.RESTART, ClusterAction.STOP].includes(triggeredAction!) &&
      isChangingServiceState) ||
    deleteConfirmLoading;
  const isDeleteServiceBtnDisabled =
    !canScale ||
    isTrialUser ||
    deleteConfirmLoading ||
    ([ClusterAction.RESTART, ClusterAction.STOP].includes(triggeredAction!) && isChangingServiceState);

  async function handleMenuItemClick(action: ClusterAction) {
    try {
      await handleServiceRestartorStop({ action, all: true });
      handleDDlVisibleChange(false);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (deleteConfirmLoading) {
      setIsDdlVisible(false);
    }
  }, [deleteConfirmLoading]);

  const menu = (
    <Menu>
      <Menu.Item key="1" disabled={isStartBtnDisabled} onClick={() => handleMenuItemClick(ClusterAction.START)}>
        <FormattedMessage id="clusterConfiguration.clusterServicesControl.headerOptions.options.startAll" />
      </Menu.Item>
      <Menu.Item key="2" disabled={isStopBtnDisabled} onClick={() => handleMenuItemClick(ClusterAction.STOP)}>
        <FormattedMessage id="clusterConfiguration.clusterServicesControl.headerOptions.options.stopAll" />
      </Menu.Item>
      <Menu.Item key="3" disabled={isRestartBtnDisabled} onClick={() => handleMenuItemClick(ClusterAction.RESTART)}>
        <FormattedMessage id="clusterConfiguration.clusterServicesControl.headerOptions.options.restartAll" />
      </Menu.Item>
      <Menu.Item key="4" hidden={!canScale} disabled={isDeleteServiceBtnDisabled}>
        <Tooltip
          title={
            isTrialUser && (
              <FormattedMessage id="clusterConfiguration.clusterServicesControl.headerOptions.tooltipCantAddForTrialUser" />
            )
          }
        >
          <AddRemoveClusterServicePopconfirm
            serviceCount={serviceCount}
            clusterName={clusterName}
            serviceType={serviceType}
            popoverType={'delete'}
            minNodesCount={minNodesCount}
            maxNodesCount={maxNodesCount}
            placement="topRight"
            confirmLoading={deleteConfirmLoading}
            setConfirmLoading={setDeleteConfirmLoading}
            isAddingDisabled={isAddingDisabled}
            clusterScalingNodes={clusterScalingNodes}
          >
            <a className="cluster-services-control-header-options__delete-service-menu-item" href=" ">
              <FormattedMessage id="clusterConfiguration.clusterServicesControl.headerOptions.options.deleteServices" />
            </a>
          </AddRemoveClusterServicePopconfirm>
        </Tooltip>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="cluster-services-control-header-options__wrapper">
      {canControl && canScale && (
        <>
          <AddRemoveClusterServicePopconfirm
            serviceCount={serviceCount}
            clusterName={clusterName}
            serviceType={serviceType}
            popoverType={'add'}
            minNodesCount={minNodesCount}
            maxNodesCount={maxNodesCount}
            placement="bottomRight"
            confirmLoading={addConfirmLoading}
            setConfirmLoading={setAddConfirmLoading}
            isAddingDisabled={isAddingDisabled}
            clusterScalingNodes={clusterScalingNodes}
          >
            <Tooltip
              title={
                isAddingDisabled ? (
                  isTrialUser ? (
                    <FormattedMessage id="clusterConfiguration.clusterServicesControl.headerOptions.tooltipCantAddForTrialUser" />
                  ) : (
                    <FormattedMessage
                      id="clusterConfiguration.clusterServicesControl.headerOptions.tooltipCantAddReachedMaxCount"
                      values={{
                        serviceType: serviceType.charAt(0).toUpperCase() + serviceType.slice(1),
                      }}
                    />
                  )
                ) : (
                  <FormattedMessage
                    id="clusterConfiguration.clusterServicesControl.headerOptions.addServices"
                    values={{
                      serviceName: serviceType.charAt(0).toUpperCase() + serviceType.slice(1),
                    }}
                  />
                )
              }
            >
              <PlusOutlined
                className={clsx('cluster-services-control-header-options__add-services', {
                  disabled: isAddingDisabled,
                })}
              />
            </Tooltip>
          </AddRemoveClusterServicePopconfirm>
        </>
      )}
      {canControl && (
        <Dropdown trigger={['click']} visible={isDdlVisible} onVisibleChange={handleDDlVisibleChange} overlay={menu}>
          <EllipsisOutlined rotate={90} disabled={deleteConfirmLoading} />
        </Dropdown>
      )}
    </div>
  );
}

export default ClusterServiceTableHeaderOptions;
