import './ConsumptionTable.less';

import { TableProps as RcTableProps } from 'rc-table/lib/Table';
import React, { useMemo, useState } from 'react';
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { format } from 'date-fns';
import UnitsConsumptionDetailsPopover from 'components/MyAccount/UnitsConsumptionDetails/UnitsConsumptionDetailsResult/UnitsConsumptionDetailsPopover/UnitsConsumptionDetailsPopover';
import { getClustersConsumption } from 'utils/cluster';

interface ConsumptionTableProps {
  filteredClusters?: ReturnType<typeof getClustersConsumption>;
}

function ConsumptionTable({ filteredClusters }: ConsumptionTableProps) {
  let [expandedRows, setExpandedRows] = useState<string[]>([]);

  let dataSource: RcTableProps['data'] = useMemo(
    () =>
      (filteredClusters ?? [])?.flatMap((cluster) => {
        return cluster.consumption.map((consumption, i) => {
          return {
            key: i === 0 ? cluster.id : `${cluster.id}:${consumption.monthFormat}`,
            cluster: i === 0 ? cluster.name : undefined,
            dayFormat: consumption.monthFormat,
            incorta: consumption.incorta,
            spark: consumption.spark,
            total: consumption.total,
            hours: undefined,
            children: consumption.days.map((day) => {
              return {
                key: `${day.dayFormat}:${day.monthFormat}`,
                dayFormat: day.dayFormat,
                incorta: day.incorta,
                spark: day.spark,
                total: day.total,
                hours: day.hours,
                day: day.day,
              };
            }),
          };
        });
      }),
    [filteredClusters]
  );

  return (
    <div className="ConsumptionTable" style={{ margin: '24px 0' }}>
      <Table
        size="middle"
        pagination={false}
        expandable={{
          expandIconColumnIndex: 1,
          expandIcon({ expanded, onExpand, record }) {
            if ((record as any).children) {
              return expanded ? (
                <CaretDownOutlined style={{ marginRight: 4 }} onClick={(e) => onExpand(record, e)} />
              ) : (
                <CaretRightOutlined style={{ marginRight: 4 }} onClick={(e) => onExpand(record, e)} />
              );
            }
            return null;
          },
        }}
        //@ts-ignore
        dataSource={dataSource}
        onExpandedRowsChange={(rows) => {
          setExpandedRows(rows as string[]);
        }}
        columns={[
          {
            title: 'Clusters',
            dataIndex: 'cluster',
            key: 'cluster',
            render(value, row: any) {
              if (row.cluster) {
                let relatedDataSource = dataSource?.filter((source: any) => {
                  return source.key === row.key || source.key.startsWith(row.key);
                });
                let opeRelatedDataSource = relatedDataSource
                  ?.filter((source: any) => expandedRows.includes(source.key))
                  .flatMap((source: any) => source.children);
                return {
                  children: value,
                  props: {
                    rowSpan: (relatedDataSource?.length ?? 0) + (opeRelatedDataSource?.length ?? 0),
                  },
                };
              }
              return {
                children: value,
                props: {
                  rowSpan: 0,
                },
              };
            },
            width: 250,
          },
          {
            title: 'Month/Days',
            dataIndex: 'dayFormat',
            key: 'dayFormat',
            width: 200,
          },
          {
            title: 'IPU Consumption',
            children: [
              {
                title: 'Incorta Units',
                dataIndex: 'incorta',
                key: 'incorta',
              },
              {
                title: 'Spark Units',
                dataIndex: 'spark',
                key: 'spark',
              },
            ],
          },

          {
            title: 'Total Units',
            dataIndex: 'total',
            key: 'total',
          },
          {
            title: '',
            dataIndex: 'hours',
            key: 'hours',
            width: 70,
            align: 'right',
            render(_, data: any) {
              if (data.hours) {
                return <UnitsConsumptionDetailsPopover day={format(data.day, 'd MMM yyyy')} hours={data.hours} />;
              }
              return null;
            },
          },
        ]}
      />
    </div>
  );
}

export default ConsumptionTable;
