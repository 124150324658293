import { useCallback, useContext } from 'react';
import { MutationFunction, UseQueryResult, useMutation, useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { addDays, format } from 'date-fns';
import _ from 'lodash';
import { queryClient } from 'index';
import { QueryServerKeys } from 'constants/QueryServerKeys';
import { SessionContext } from 'auth/SessionProvider';
import {
  addIpWhiteList,
  addUserRole,
  changeCopilotState,
  changeDataStudioState,
  changeIncortaXState,
  changeInstanceScale,
  changeMLflowState,
  changeSqliState,
  cloneCluster,
  connectSubCluster,
  createCluster,
  createScheduledEvent,
  createSubCluster,
  createSubscription,
  deleteScheduledEvent,
  deleteSubCluster,
  deleteSupportToken,
  disconnectSubCluster,
  generateSupportToken,
  getAllClusters,
  getAllClustersForPulling,
  getAuthUsers,
  getAutoScaleHistory,
  getAzureClusterPipelineState,
  getBlueprints,
  getClusterTenants,
  getClusterWhiteList,
  getConsumptionInfoTODO,
  getDataStudio,
  getImageSizes,
  getImages,
  getPremiumPackageFlag,
  getRegions,
  getScheduledEvent,
  getServicesStatus,
  getSupportToken,
  getSupportedVersions,
  getSuspendTimes,
  getSystemConfigurations,
  getTenants,
  markMessageAsRead,
  removeClusterIpWhitelist,
  removeClusterUser,
  storeAzurePlan,
  switchAzureSynapse,
  updateCluster,
  updateClusterIpWhitelist,
  updateClusterUserRole,
  updateScheduledEvent,
  updateSubCluster,
  upgradeDisconnectedCluster,
  upgradeVersion,
  withdrawClusterUserInvitation,
} from 'services/cluster';
import { acceptUserCluster } from 'services/user';
import {
  AutoScaleHistoryRecord,
  ClusterFormData,
  CreateScheduledEventEndpointBody,
  InstancesData,
  Notification,
  ScheduledEventsWithInfoParsedItem,
  SubClusterCreateData,
  SubClusterUpdateData,
  Subscription,
  UpdateScheduledEventEndpointBody,
} from 'types/cluster';
import { UserData } from 'types/user';
import { PULL_TIMER_SMALL } from 'utils/cluster';
import { getErrorMessage, showErrorMessage } from 'utils/errors';
import { InstancePlatform } from 'utils/platforms';

export function useClusterList() {
  const { user } = useContext(SessionContext);

  let queryOptions = useQuery(
    [QueryServerKeys.CLUSTER.CLUSTER_LIST_KEY, user!.uuid],
    () => getAllClusters(user!.uuid),
    {
      enabled: !!user?.uuid,
    }
  );

  if (queryOptions?.data) {
    const instancesInfo = queryOptions?.data?.instances?.map(({ instance }) => ({
      instanceId: instance.id,
      name: instance.name,
      analyticsNodes: instance.analyticsNodes,
      loaderNodes: instance.loaderNodes,
      sqli: !!instance.sqliEnabled ? 1 : 0,
      notebook: 1,
    }));
    if (queryOptions?.data?.status === 200 && !_.isEqual(user.instanceServices, instancesInfo)) {
      user.instanceServices = instancesInfo;
    }
  }

  useQuery(
    [QueryServerKeys.CLUSTER.CLUSTER_LIST_KEY_PULLING, user!.uuid],
    async () => getAllClustersForPulling(user!.uuid),
    {
      refetchInterval: PULL_TIMER_SMALL,
      enabled: !!queryOptions.data?.instances && !queryOptions.isFetching,
      // update CLUSTER_LIST_KEY queryOptions
      async onSuccess(data) {
        if (queryOptions.isFetching) {
          return;
        }
        await queryClient.cancelQueries([QueryServerKeys.CLUSTER.CLUSTER_LIST_KEY, user!.uuid]);
        queryClient.setQueryData(
          [QueryServerKeys.CLUSTER.CLUSTER_LIST_KEY, user!.uuid],
          _.merge(queryOptions.data, data)
        );
      },
    }
  );

  return queryOptions;
}

export async function updateClusterListQuery(
  userID: string,
  cb: (instancesData: InstancesData | undefined) => InstancesData
) {
  await queryClient.cancelQueries([QueryServerKeys.CLUSTER.CLUSTER_LIST_KEY_PULLING, userID]);
  await queryClient.cancelQueries([QueryServerKeys.CLUSTER.CLUSTER_LIST_KEY, userID]);
  queryClient.setQueryData([QueryServerKeys.CLUSTER.CLUSTER_LIST_KEY, userID], cb);
}

export function useClusterListRefetch() {
  const { user } = useContext(SessionContext);
  let requestCluster = useCallback(async () => {
    return queryClient.invalidateQueries([QueryServerKeys.CLUSTER.CLUSTER_LIST_KEY, user!.uuid], {
      refetchInactive: true,
      refetchActive: true,
    });
  }, [user]);

  return requestCluster;
}

interface UseSupportTokenArg {
  clusterName: string;
}
export function useSupportToken({ clusterName }: UseSupportTokenArg) {
  const { user } = useContext(SessionContext);

  let { data, isLoading: isTokenLoading } = useQuery(
    [QueryServerKeys.CLUSTER.SUPPORT_TOKEN_KEY, clusterName],
    () => getSupportToken({ userId: user!.uuid, instanceName: clusterName }),
    { retry: false }
  );

  let { mutate: requestNewToken, isLoading: isGenerating } = useMutation(
    async ({ revokeFirst = false }: { revokeFirst?: boolean } = {}) => {
      if (revokeFirst) {
        await deleteSupportToken({
          userId: user!.uuid,
          instanceName: clusterName,
        });
      }
      let res = await generateSupportToken({
        userId: user!.uuid,
        instanceName: clusterName,
      });
      return res;
    },
    {
      onSuccess(data) {
        queryClient.cancelQueries([QueryServerKeys.CLUSTER.SUPPORT_TOKEN_KEY, clusterName]);
        queryClient.setQueryData([QueryServerKeys.CLUSTER.SUPPORT_TOKEN_KEY, clusterName], data);
      },
    }
  );

  let { mutate: requestRevokeToken } = useMutation(
    async () => {
      let res = deleteSupportToken({
        userId: user!.uuid,
        instanceName: clusterName,
      });
      return res;
    },
    {
      async onMutate() {
        await queryClient.cancelQueries([QueryServerKeys.CLUSTER.SUPPORT_TOKEN_KEY, clusterName]);
        const previousToken = queryClient.getQueryData([QueryServerKeys.CLUSTER.SUPPORT_TOKEN_KEY, clusterName]);
        queryClient.setQueryData([QueryServerKeys.CLUSTER.SUPPORT_TOKEN_KEY, clusterName], null);
        return { previousToken };
      },
      onError: (_err, _newTodo, context: any) => {
        queryClient.setQueryData([QueryServerKeys.CLUSTER.SUPPORT_TOKEN_KEY, clusterName], context.previousToken);
      },
    }
  );

  return {
    data: data?.data,
    requestNewToken,
    requestRevokeToken,
    isGenerating,
    isTokenLoading,
  };
}

export function useClusterSizeTypes({
  clusterCode,
  currentPlatform,
}: {
  clusterCode: string;
  currentPlatform: InstancePlatform;
}) {
  return useQuery(
    [QueryServerKeys.CLUSTER.CLUSTER_SIZE_TYPES_KEY, currentPlatform, clusterCode],
    async () => getImageSizes({ clusterCode }),
    {
      enabled: !!currentPlatform && !!clusterCode,
    }
  );
}

export function useMutateCluster({ clusterName }: { clusterName: string }) {
  const { user } = useContext(SessionContext);
  let requestClusters = useClusterListRefetch();

  let queryOptions = useMutation(
    async (args: any) => {
      const res = await updateCluster({
        userId: user!.uuid,
        clusterName: clusterName,
        ...args,
      });
      await requestClusters();
      return res;
    },
    {
      onError(error: any) {
        let errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          showErrorMessage(errorMessage);
        }
      },
    }
  );

  return queryOptions;
}

export function useMutateClusterScale({ clusterName }: { clusterName: string }) {
  const { user } = useContext(SessionContext);
  let requestClusters = useClusterListRefetch();

  let queryOptions = useMutation(
    async (scale: { loaderReplicas?: number; analyticsReplicas?: number }) => {
      const res = await changeInstanceScale({
        userId: user!.uuid,
        clusterName: clusterName,
        data: scale,
      });
      await requestClusters();
      return res;
    },
    {
      onError(error: any) {
        let errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          showErrorMessage(errorMessage);
        }
      },
    }
  );

  return queryOptions;
}

export function useSuspendTimes() {
  return useQuery(QueryServerKeys.USER.SUSPEND_TIMES_KEY, () => getSuspendTimes());
}

// eslint-disable-next-line import/no-unused-modules
export function useMutateSqliState({ clusterName }: { clusterName: string }) {
  const { user } = useContext(SessionContext);
  let requestClusters = useClusterListRefetch();

  let queryOptions = useMutation(
    async (enable: boolean) => {
      const res = await changeSqliState({
        userId: user!.uuid,
        clusterName: clusterName,
        sqliEnabled: enable,
      });
      await requestClusters();
      return res;
    },
    {
      onError(error: any) {
        let errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          showErrorMessage(errorMessage);
        }
      },
    }
  );

  return queryOptions;
}

export function useMutateIncortaXState({ clusterName }: { clusterName: string }) {
  const { user } = useContext(SessionContext);

  const queryOptions = useMutation(
    async (enable: boolean) => {
      const res = await changeIncortaXState({
        userId: user!.uuid,
        clusterName: clusterName,
        incortaXEnabled: enable,
      });
      return res;
    },
    {
      async onSuccess() {
        await queryClient.invalidateQueries([QueryServerKeys.CLUSTER.CLUSTER_LIST_KEY, user.uuid], {
          refetchInactive: true,
          refetchActive: true,
        });
      },
      onError(error: any) {
        let errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          showErrorMessage(errorMessage);
        }
      },
    }
  );

  return queryOptions;
}

export function useConsumptionInfo({ start, end }: { start: Date; end: Date }) {
  const { user } = useContext(SessionContext);
  let startDate = format(start, 'yyyy-MM-dd');
  let endDate = format(addDays(end, 1), 'yyyy-MM-dd');
  let queryOptions = useQuery([QueryServerKeys.USER.CONSUMPTION_INFO_KEY, user!.uuid, startDate, endDate], () =>
    getConsumptionInfoTODO({
      userId: user!.uuid,
      startDate,
      endDate,
    })
  );

  return queryOptions;
}

export function useAuthUsers({ clusterName }: { clusterName: string }) {
  const { user } = useContext(SessionContext);

  let queryOptions = useQuery(
    [QueryServerKeys.USER.AUTH_USERS_KEY, user!.uuid, clusterName],
    () =>
      getAuthUsers({
        userId: user!.uuid,
        clusterName,
      }),
    {
      onError(error: any) {
        let errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          showErrorMessage(errorMessage);
        }
      },
    }
  );

  return queryOptions;
}

function useAuthUsersRefetch({ clusterName }: { clusterName: string }) {
  const { user } = useContext(SessionContext);

  let requestCluster = useCallback(async () => {
    return queryClient.invalidateQueries([QueryServerKeys.USER.AUTH_USERS_KEY, user!.uuid, clusterName], {
      refetchInactive: true,
      refetchActive: true,
    });
  }, [clusterName, user]);

  return requestCluster;
}

export function useAddAuthRole({ clusterName }: { clusterName: string }) {
  const { user: me } = useContext(SessionContext);

  let requestClusters = useClusterListRefetch();
  let requestAuthRoleUsers = useAuthUsersRefetch({ clusterName });

  let queryOptions = useMutation(
    async (user: { userEmail: string; roleName: string }) => {
      const res = await addUserRole({
        userId: me!.uuid,
        clusterName,
        user,
      });
      await requestClusters();
      await requestAuthRoleUsers();
      return res;
    },
    {
      onError(error: any) {
        let errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          showErrorMessage(errorMessage);
        }
      },
    }
  );

  return queryOptions;
}

export function useUpdateClusterUserRole({ clusterName }: { clusterName: string }) {
  let { user: me } = useContext(SessionContext);

  let requestClusters = useClusterListRefetch();
  let requestAuthRoleUsers = useAuthUsersRefetch({ clusterName });

  let queryOptions = useMutation(
    async (user: { userEmail: string; roleName: string }) => {
      const res = await updateClusterUserRole({
        userId: me!.uuid,
        clusterName,
        user,
      });
      await requestClusters();
      await requestAuthRoleUsers();
      return res;
    },
    {
      onError(error: any) {
        let errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          showErrorMessage(errorMessage);
        }
      },
    }
  );

  return queryOptions;
}

export function useRemoveClusterUser({ clusterName }: { clusterName: string }) {
  let { user: me } = useContext(SessionContext);

  let requestClusters = useClusterListRefetch();
  let requestAuthRoleUsers = useAuthUsersRefetch({ clusterName });

  let queryOptions = useMutation(
    async (user: { id: string }) => {
      const res = await removeClusterUser({
        userId: me!.uuid,
        clusterName,
        user,
      });
      await requestClusters();
      await requestAuthRoleUsers();
      return res;
    },
    {
      onError(error: any) {
        let errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          showErrorMessage(errorMessage);
        }
      },
    }
  );

  return queryOptions;
}

export function useWithdrawClusterUserInvitation({ clusterName }: { clusterName: string }) {
  let { user: me } = useContext(SessionContext);

  let requestClusters = useClusterListRefetch();
  let requestAuthRoleUsers = useAuthUsersRefetch({ clusterName });

  let queryOptions = useMutation(
    async (user: { id: string }) => {
      const res = await withdrawClusterUserInvitation({
        userId: me!.uuid,
        clusterName,
        user,
      });
      await requestClusters();
      await requestAuthRoleUsers();
      return res;
    },
    {
      onError(error: any) {
        let errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          showErrorMessage(errorMessage);
        }
      },
    }
  );

  return queryOptions;
}

export function useGetClusterWhiteList({ clusterName }: { clusterName: string }) {
  let { user } = useContext(SessionContext);

  let queryOptions = useQuery(
    [QueryServerKeys.CLUSTER.CUSTER_WHITE_LIST, user!.uuid, clusterName],
    () =>
      getClusterWhiteList({
        userId: user!.uuid,
        clusterName,
      }),
    {
      onError(error: any) {
        let errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          showErrorMessage(errorMessage);
        }
      },
    }
  );

  return queryOptions;
}

function useClusterWhitelistRefetch({ clusterName }: { clusterName: string }) {
  const { user } = useContext(SessionContext);

  let requestCluster = useCallback(async () => {
    return queryClient.invalidateQueries([QueryServerKeys.CLUSTER.CUSTER_WHITE_LIST, user!.uuid, clusterName], {
      refetchInactive: true,
      refetchActive: true,
    });

    // eslint-disable-next-line
  }, [clusterName, user!.uuid]);

  return requestCluster;
}

export function useAddClusterWhitelist({ clusterName }: { clusterName: string }) {
  let { user: me } = useContext(SessionContext);

  let requestClusters = useClusterListRefetch();
  let requestWhiteList = useClusterWhitelistRefetch({ clusterName });

  let queryOptions = useMutation(
    async (whitelist: { range: string; description: string }) => {
      const res = await addIpWhiteList({
        userId: me!.uuid,
        clusterName,
        whitelist,
      });
      await requestClusters();
      await requestWhiteList();
      return res;
    },
    {
      onError(error: any) {
        let errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          showErrorMessage(errorMessage);
        }
      },
    }
  );

  return queryOptions;
}

export function useRemoveIpWhitelist({ clusterName }: { clusterName: string }) {
  let { user: me } = useContext(SessionContext);

  let requestClusters = useClusterListRefetch();
  let requestWhiteList = useClusterWhitelistRefetch({ clusterName });

  let queryOptions = useMutation(
    async (whitelistId: number) => {
      const res = await removeClusterIpWhitelist({
        userId: me!.uuid,
        clusterName,
        whitelistId,
      });
      await requestClusters();
      await requestWhiteList();
      return res;
    },
    {
      onError(error: any) {
        let errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          showErrorMessage(errorMessage);
        }
      },
    }
  );

  return queryOptions;
}

export function useUpdateClusterIpWhitelist({ clusterName }: { clusterName: string }) {
  let { user: me } = useContext(SessionContext);

  let requestClusters = useClusterListRefetch();
  let requestWhiteList = useClusterWhitelistRefetch({ clusterName });

  let queryOptions = useMutation(
    async ({
      whitelistId,
      whitelist,
    }: {
      whitelistId: number;
      whitelist: {
        active?: boolean;
        range?: string;
        description?: string;
      };
    }) => {
      const res = await updateClusterIpWhitelist({
        userId: me!.uuid,
        clusterName,
        whitelistId,
        whitelist,
      });
      await requestClusters();
      await requestWhiteList();
      return res;
    },
    {
      onError(error: any) {
        let errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          showErrorMessage(errorMessage);
        }
      },
    }
  );

  return queryOptions;
}

export function useOperators() {
  const queryOptions = useQuery([QueryServerKeys.CLUSTER.OPERATORS_KEY], getRegions, {
    staleTime: 10 * (60 * 1000), // 10 mins
    cacheTime: 15 * (60 * 1000), // 15 mins
    refetchOnWindowFocus: false,
  });

  return queryOptions;
}

export function useEnableAzureSynapse({
  userID,
  instanceName,
  instanceId,
}: {
  userID: string;
  instanceName: string;
  instanceId: string;
}) {
  const queryOptions = useMutation(
    async ({
      tenant,
      username,
      password,
      enableMicrosoftSynapse,
    }: {
      tenant: string;
      username: string;
      password: string;
      enableMicrosoftSynapse: boolean;
    }) => {
      return await switchAzureSynapse({
        userID,
        instanceName,
        instanceId,
        tenant,
        username,
        password,
        enableMicrosoftSynapse,
      });
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([QueryServerKeys.CLUSTER.CLUSTER_LIST_KEY, userID]);
      },
      onError(error: any) {
        let errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          showErrorMessage(errorMessage);
        }
      },
    }
  );

  return queryOptions;
}

export function useAzureClusterPipelineState({
  email,
  userID,
  enabled = true,
}: {
  email: string;
  userID: string;
  enabled: boolean;
}) {
  const queryOptions = useQuery(
    [QueryServerKeys.AZURE.PIPELINE_KEY],
    async () => {
      const result = await getAzureClusterPipelineState({ email, userID });
      return result.data;
    },
    {
      staleTime: 2 * (60 * 1000), // 2 mins
      refetchInterval: 2 * (60 * 1000), // 2 mins
      refetchIntervalInBackground: true,
      enabled,
    }
  );

  return queryOptions;
}

export function useMarkNotificationAsRead() {
  return useMutation({
    mutationFn: (({ userId, notificationId }: { userId: string; notificationId: string }) =>
      markMessageAsRead(userId, notificationId)) as MutationFunction,
    onMutate({ notificationId, userId }) {
      queryClient.setQueryData<InstancesData>([QueryServerKeys.CLUSTER.CLUSTER_LIST_KEY, userId], (data) => {
        let updatedNotifications: Notification[] | undefined = data?.notifications?.notifications;
        if (notificationId) {
          const notification = updatedNotifications?.find((notId) => notId.id === notificationId);
          if (notification) notification.read = true;
        } else {
          updatedNotifications = updatedNotifications?.map((notification) => ({
            ...notification,
            read: true,
          }));
        }

        return {
          ...data,
          notifications: { notifications: updatedNotifications },
        } as InstancesData;
      });
    },
  }).mutateAsync;
}

export function useCreateSubscription() {
  const queryOptions = useMutation(
    async ({ user, subscription }: { user: UserData; subscription: Subscription }) => {
      return await createSubscription({ user, subscription });
    },
    {
      mutationKey: [QueryServerKeys.AZURE.CREATE_SUBSCRIPTION_KEY],
      async onSuccess(res, { subscription }) {
        await storeAzurePlan(res?.data?.body, subscription);
      },
      onError(error: any) {
        let errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          showErrorMessage(errorMessage);
        }
      },
    }
  );

  return queryOptions;
}

export function useCreateCluster() {
  const queryOptions = useMutation(
    async ({ userId, cluster }: { userId: string; cluster: ClusterFormData }) => {
      await createCluster({ userId, cluster });
    },
    {
      mutationKey: [QueryServerKeys.CLUSTER.CREATE_CLUSTER_KEY],
      async onSuccess(data, { userId }) {
        await queryClient.invalidateQueries([QueryServerKeys.CLUSTER.CLUSTER_LIST_KEY, userId], {
          refetchInactive: true,
          refetchActive: true,
        });
        // refresh user data (/me)
        queryClient.invalidateQueries(QueryServerKeys.USER.GET_USER_DATA);
      },
      onError(error: any) {
        let errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          showErrorMessage(errorMessage);
        }
      },
    }
  );

  return queryOptions;
}

export function useGetIncortaImages() {
  const queryOptions = useQuery(
    [QueryServerKeys.CLUSTER.GET_INCORTA_IMAGES_KEY],
    async () => {
      const data = await getImages();
      return data.images;
    },
    {
      onError(error: any) {
        let errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          showErrorMessage(errorMessage);
        }
      },
    }
  );

  return queryOptions;
}

export function useGetTenants() {
  const queryOptions = useQuery(
    [QueryServerKeys.TENANT.TENANT_LIST],
    async () => {
      const result = await getTenants();
      return result.data;
    },
    {
      staleTime: 2 * 1000, // 2 sec
    }
  );

  return queryOptions;
}

export function useClusterTenants({
  instanceID,
  userId,
  clusterName,
}: {
  instanceID: string;
  userId: string;
  clusterName: string;
}) {
  const queryOptions = useQuery([QueryServerKeys.CLUSTER.CLUSTER_TENANTS, instanceID], async () => {
    const result = await getClusterTenants({ userId, clusterName });
    return result.data.tenants;
  });
  return queryOptions;
}

export function useGetBlueprints({ fields }: { fields?: string[] }) {
  const queryOptions = useQuery(
    [QueryServerKeys.BLUEPRINT.BLUEPRINT_LIST],
    async () => {
      const result = await getBlueprints({ fields });
      return result.data;
    },
    {
      staleTime: 2 * 1000, // 2 sec
    }
  );

  return queryOptions;
}

export function useCloneCluster({ refetchClusterList }: { refetchClusterList: boolean }) {
  const queryOptions = useMutation(
    async ({ userId, clusterName, name }: { userId: string; clusterName: string; name: string }) => {
      return await cloneCluster({
        userId,
        clusterName,
        name,
      });
    },
    {
      async onSuccess(data, { userId }) {
        if (refetchClusterList) {
          // refresh clusters list
          await queryClient.invalidateQueries([QueryServerKeys.CLUSTER.CLUSTER_LIST_KEY, userId], {
            refetchInactive: true,
            refetchActive: true,
          });
          // refresh user data (/me)
          queryClient.invalidateQueries(QueryServerKeys.USER.GET_USER_DATA);
        }
      },
      onError(error: any) {
        const errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          showErrorMessage(errorMessage);
        }
      },
    }
  );

  return queryOptions;
}

export function useGetSupportedVersions() {
  const queryOptions = useMutation(
    async ({ slug, instanceId }: { slug: string; instanceId: string | undefined }) => {
      return await getSupportedVersions(slug, instanceId);
    },
    {
      onError(error: any) {
        const errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          showErrorMessage(errorMessage);
        }
      },
    }
  );

  return queryOptions;
}

export function useUpdateSubCluster({ refetchClusterList }: { refetchClusterList: boolean }) {
  const queryOptions = useMutation(
    async ({ userId, clusterName, data }: { userId: string; clusterName: string; data: SubClusterUpdateData }) => {
      return await updateSubCluster({
        userId,
        clusterName,
        data,
      });
    },
    {
      async onSuccess(data, { userId }) {
        if (refetchClusterList) {
          return await queryClient.invalidateQueries([QueryServerKeys.CLUSTER.CLUSTER_LIST_KEY, userId], {
            refetchInactive: true,
            refetchActive: true,
          });
        }
      },
      onError(error: any) {
        const errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          showErrorMessage(errorMessage);
        }
      },
    }
  );

  return queryOptions;
}

export function useUpdateSubClusterScale({ refetchClusterList }: { refetchClusterList: boolean }) {
  const queryOptions = useMutation(
    async ({ userId, clusterName, data }: { userId: string; clusterName: string; data: SubClusterUpdateData }) => {
      return await changeInstanceScale({
        userId,
        clusterName,
        data,
      });
    },
    {
      async onSuccess(data, { userId }) {
        if (refetchClusterList) {
          return await queryClient.invalidateQueries([QueryServerKeys.CLUSTER.CLUSTER_LIST_KEY, userId], {
            refetchInactive: true,
            refetchActive: true,
          });
        }
      },
      onError(error: any) {
        const errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          showErrorMessage(errorMessage);
        }
      },
    }
  );

  return queryOptions;
}

export function useRequestCreateSubCluster({ refetchClusterList }: { refetchClusterList: boolean }) {
  const queryOptions = useMutation(
    async ({ userId, clusterName, data }: { userId: string; clusterName: string; data: SubClusterCreateData }) => {
      return await createSubCluster({
        userId,
        clusterName,
        data,
      });
    },
    {
      async onSuccess(data, { userId }) {
        if (refetchClusterList) {
          return await queryClient.invalidateQueries([QueryServerKeys.CLUSTER.CLUSTER_LIST_KEY, userId], {
            refetchInactive: true,
            refetchActive: true,
          });
        }
      },

      onError(error: any) {
        const errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          showErrorMessage(errorMessage);
        }
      },
    }
  );

  return queryOptions;
}

export function useDeleteSubCluster() {
  const queryOptions = useMutation(
    async ({
      userId,
      clusterName,
      subClusterName,
    }: {
      userId: string;
      clusterName: string;
      subClusterName: string;
    }) => {
      return await deleteSubCluster({
        userId,
        clusterName,
        subClusterName,
      });
    },
    {
      async onSuccess(_data, { userId }) {
        return await queryClient.invalidateQueries([QueryServerKeys.CLUSTER.CLUSTER_LIST_KEY, userId], {
          refetchInactive: true,
          refetchActive: true,
        });
      },

      onError(error: any) {
        const errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          showErrorMessage(errorMessage);
        }
      },
    }
  );

  return queryOptions;
}

export function useConnectSubCluster() {
  const queryOptions = useMutation(
    async ({
      userId,
      clusterName,
      subClusterName,
    }: {
      userId: string;
      clusterName: string;
      subClusterName: string;
    }) => {
      return await connectSubCluster({
        userId,
        clusterName,
        subClusterName,
      });
    },
    {
      async onSuccess(_data, { userId }) {
        return await queryClient.invalidateQueries([QueryServerKeys.CLUSTER.CLUSTER_LIST_KEY, userId], {
          refetchInactive: true,
          refetchActive: true,
        });
      },

      onError(error: any) {
        const errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          showErrorMessage(errorMessage);
        }
      },
    }
  );

  return queryOptions;
}

export function useDisconnectSubCluster() {
  const queryOptions = useMutation(
    async ({
      userId,
      clusterName,
      subClusterName,
    }: {
      userId: string;
      clusterName: string;
      subClusterName: string;
    }) => {
      return await disconnectSubCluster({
        userId,
        clusterName,
        subClusterName,
      });
    },
    {
      async onSuccess(_data, { userId }) {
        return await queryClient.invalidateQueries([QueryServerKeys.CLUSTER.CLUSTER_LIST_KEY, userId], {
          refetchInactive: true,
          refetchActive: true,
        });
      },

      onError(error: any) {
        const errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          showErrorMessage(errorMessage);
        }
      },
    }
  );

  return queryOptions;
}

export function useGetResourcesRegions({
  configKey,
  configType,
  defaultValue,
}: {
  configKey: string;
  configType: string;
  defaultValue: string;
}) {
  const queryOptions = useQuery(
    [QueryServerKeys.AZURE.RESOURCES_REGIONS],
    async () => {
      const result = await getSystemConfigurations({
        configKey,
        configType,
        defaultValue,
      });
      return result.data?.sysConfig?.trim?.().split?.(',');
    },
    {
      staleTime: 2 * 1000, // 2 sec
    }
  );

  return queryOptions;
}

export function useAcceptUserCluster({
  userID,
  instanceID,
  isEnabled,
}: {
  userID: string;
  instanceID: string;
  isEnabled: boolean;
}) {
  const queryOptions = useQuery(
    [QueryServerKeys.CLUSTER.ACCEPT_USER_CLUSTER, instanceID],
    async () => {
      return await acceptUserCluster(userID, instanceID);
    },
    {
      enabled: !!isEnabled,
      staleTime: 2 * 1000, // 2 sec
      onError(error: AxiosError) {
        const errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          showErrorMessage(errorMessage);
        }
      },
    }
  );

  return queryOptions;
}

const REFETCH_SERVICE_STATUS_INTERVAL = 5000;

export function useGetServicesStatus({ instanceId, isEnabled }: { instanceId: string; isEnabled: boolean }) {
  const queryOptions = useQuery(
    [QueryServerKeys.CLUSTER.GET_SERVICES_STATUS, instanceId],
    async () => {
      return await getServicesStatus(instanceId);
    },
    {
      enabled: !!isEnabled,
      // staleTime: 2 * 1000, // 2 sec
      refetchInterval: (data) => {
        // console.log('', data);
        //  ![ServiceStatus.RUNNING, ServiceStatus.NOT_RUNNING].includes(
        //   data?.data?.[
        //     (serviceNode + 'Status') as ServiceType
        //   ] as ServiceStatus,
        // )
        //     ?
        return REFETCH_SERVICE_STATUS_INTERVAL;
        //   : false;
      },
      refetchOnReconnect: false,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      onError(err) {
        throw err;
      },
    }
  );

  return queryOptions;
}

export function useUpgradeVersion({
  handleOnSucces,
  handleOnError,
}: {
  handleOnSucces?: Function;
  handleOnError?: Function;
}) {
  const queryOptions = useMutation(
    async ({
      userId,
      clusterName,
      version,
      byBuildNo,
    }: {
      userId: string;
      clusterName: string;
      version: string;
      byBuildNo: string;
    }) => {
      return await upgradeVersion({
        userId,
        clusterName,
        version,
        byBuildNo,
      });
    },
    {
      mutationKey: [QueryServerKeys.CLUSTER.UPGRADE_VERSION],
      async onSuccess(data, { userId }) {
        handleOnSucces?.(data);
      },

      onError(error: any) {
        const errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          showErrorMessage(errorMessage);
        }
        handleOnError?.(errorMessage);
      },
    }
  );

  return queryOptions;
}

export function useUpgradeDisconnectedCluster({
  handleOnSucces,
  handleOnError,
}: {
  handleOnSucces?: Function;
  handleOnError?: Function;
}) {
  const queryOptions = useMutation(
    async ({
      userId,
      clusterName,
      version,
      byBuildNo,
    }: {
      userId: string;
      clusterName: string;
      version: string;
      byBuildNo: string;
    }) => {
      return await upgradeDisconnectedCluster({
        userId,
        clusterName,
        version,
        byBuildNo,
      });
    },
    {
      mutationKey: [QueryServerKeys.CLUSTER.UPGRADE_DISCONNECTED],
      async onSuccess(data, { userId }) {
        handleOnSucces?.(data);
      },

      onError(error: any) {
        handleOnError?.(error);
      },
    }
  );

  return queryOptions;
}

export function useGetAutoScaleHistory({
  instanceId,
  hours,
  enabled = true,
}: {
  instanceId: string;
  hours?: number;
  enabled?: boolean;
}) {
  const queryOptions = useQuery(
    [QueryServerKeys.CLUSTER.AUTO_SCALE_HISTORY, hours ?? 24],
    async () => {
      const result = await getAutoScaleHistory({
        instanceId,
        hours,
      });
      return result.data;
    },
    {
      enabled,
      staleTime: 2 * 1000, // 2 sec
      refetchInterval: 5 * (60 * 1000), // 5 mins
      select(result) {
        const analytics: Record<string, AutoScaleHistoryRecord[]> = result.analytics || {};
        const loader: Record<string, AutoScaleHistoryRecord[]> = result.loader || {};
        let analyticsLargestData = 0;
        let loaderLargestData = 0;

        Object.keys(analytics).forEach((replicaKey) => {
          analytics[replicaKey].forEach((item) => {
            if (!analyticsLargestData || item.total_queries > analyticsLargestData) {
              analyticsLargestData = item.total_queries;
            }
          });
        });

        Object.keys(loader).forEach((replicaKey) => {
          analytics[replicaKey].forEach((item) => {
            if (!loaderLargestData || item.total_queries > loaderLargestData) {
              loaderLargestData = item.total_queries;
            }
          });
        });

        return {
          analytics,
          loader,
          analyticsLargestData,
          loaderLargestData,
        };
      },
    }
  );

  return queryOptions;
}

export function useMutateCopilotState({ clusterID }: { clusterID: string }) {
  const { user } = useContext(SessionContext);
  const queryOptions = useMutation(
    async ({ enable }: { enable: boolean }) => {
      const res = await changeCopilotState({
        userId: user!.uuid,
        clusterID,
        openAIEnabled: enable,
      });
      return res;
    },
    {
      async onSuccess() {
        await queryClient.invalidateQueries([QueryServerKeys.CLUSTER.CLUSTER_LIST_KEY, user.uuid], {
          refetchInactive: true,
          refetchActive: true,
        });
      },
      onError(error: any) {
        let errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          showErrorMessage(errorMessage);
        }
      },
    }
  );

  return queryOptions;
}

export function useGetScheduledEvents({
  instanceID,
}: {
  instanceID: string;
}): UseQueryResult<ScheduledEventsWithInfoParsedItem[]> {
  const queryOptions = useQuery(
    [QueryServerKeys.CLUSTER.SCHEDULED_EVENTS, instanceID],
    async () => {
      const result = await getScheduledEvent({ instanceID });
      return result.data;
    },
    {
      staleTime: 2 * 1000, // 2 sec
      refetchInterval: 5 * (60 * 1000), // 5 mins
      select(data) {
        const events = data.message;

        const updatedEvents: ScheduledEventsWithInfoParsedItem[] = events.map((event) => {
          let updatedEventInformation: {
            analyticsReplicas?: number;
            loaderReplicas?: number;
          } = {};
          if (event.eventInformation) {
            const parsedEvent = JSON.parse(event?.eventInformation);
            const analyticsReplicas = +parsedEvent?.analyticsReplicas;
            const loaderReplicas = +parsedEvent?.loaderReplicas;
            updatedEventInformation = {
              ...(analyticsReplicas ? { analyticsReplicas } : {}),
              ...(loaderReplicas ? { loaderReplicas } : {}),
            };
          }
          return {
            ...event,
            ...(Object.keys(updatedEventInformation)?.length ? { eventInformation: updatedEventInformation } : {}),
          };
        }) as ScheduledEventsWithInfoParsedItem[];
        return updatedEvents;
      },
    }
  );

  return queryOptions;
}

export function useDeleteScheduledEvent({ instanceID }: { instanceID: string }) {
  const queryOptions = useMutation(
    async (eventID: number) => {
      const res = await deleteScheduledEvent({
        instanceID,
        eventID,
      });
      return res;
    },
    {
      async onSuccess() {
        await queryClient.invalidateQueries([QueryServerKeys.CLUSTER.SCHEDULED_EVENTS, instanceID], {
          refetchInactive: true,
        });
      },
      onError(error: any) {
        let errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          showErrorMessage(errorMessage);
        }
      },
    }
  );
  return queryOptions;
}

export function useUpdateScheduledEvent({ instanceID }: { instanceID: string }) {
  const queryOptions = useMutation(
    async ({ updatedEvent }: { updatedEvent: UpdateScheduledEventEndpointBody }) => {
      const res = await updateScheduledEvent({
        instanceID,
        updatedEvent,
      });
      return res;
    },
    {
      async onSuccess() {
        await queryClient.invalidateQueries([QueryServerKeys.CLUSTER.SCHEDULED_EVENTS, instanceID], {
          refetchInactive: true,
        });
      },
      onError(error: any) {
        let errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          showErrorMessage(errorMessage);
        }
      },
    }
  );
  return queryOptions;
}

export function useCreateScheduledEvent({ instanceID }: { instanceID: string }) {
  const queryOptions = useMutation(
    async ({ eventToCreate }: { eventToCreate: CreateScheduledEventEndpointBody }) => {
      const res = await createScheduledEvent({
        instanceID,
        eventToCreate,
      });
      return res;
    },
    {
      async onSuccess() {
        await queryClient.invalidateQueries([QueryServerKeys.CLUSTER.SCHEDULED_EVENTS, instanceID], {
          refetchInactive: true,
        });
      },
      onError(error: any) {
        let errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          showErrorMessage(errorMessage);
        }
      },
    }
  );
  return queryOptions;
}

// eslint-disable-next-line import/no-unused-modules
export function useMutateMLflowState({ instanceID }: { instanceID: string }) {
  const { user } = useContext(SessionContext);
  let requestClusters = useClusterListRefetch();

  let queryOptions = useMutation(
    async (enable: boolean) => {
      const res = await changeMLflowState({
        userID: user!.uuid,
        instanceID: instanceID,
        isEnabled: enable,
      });
      await requestClusters();
      return res;
    },
    {
      onError(error: any) {
        let errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          showErrorMessage(errorMessage);
        }
      },
    }
  );

  return queryOptions;
}

export function useFetchClusterDataStudio({ clusterID }: { clusterID: string }) {
  const { user } = useContext(SessionContext);

  return useQuery([QueryServerKeys.CLUSTER.DATASTUDIO, clusterID, user!.uuid], () =>
    getDataStudio({ userID: user.uuid, clusterID })
  );
}

export function useFetchAllowPremiumPackage() {
  const { user } = useContext(SessionContext);
  return useQuery([QueryServerKeys.CLUSTER.ALLOW_PREMIUM_PACKAGE, user!.uuid], () => getPremiumPackageFlag());
}

export function useMutateDataStudioState({ clusterID }: { clusterID: string }) {
  const { user } = useContext(SessionContext);
  const queryOptions = useMutation(
    async ({ enable }: { enable: boolean }) => {
      const res = await changeDataStudioState({
        userId: user!.uuid,
        clusterID,
        enable,
      });
      return res;
    },
    {
      async onSuccess() {
        await queryClient.invalidateQueries([QueryServerKeys.CLUSTER.DATASTUDIO], {
          refetchInactive: true,
          refetchActive: true,
        });
      },
      onError(error: any) {
        let errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          showErrorMessage(errorMessage);
        }
      },
    }
  );

  return queryOptions;
}

export function useGetSystemConfig({
  configKey,
  configType,
  defaultValue,
}: {
  configKey: string;
  configType: string;
  defaultValue: string;
}) {
  const queryOptions = useQuery(
    [QueryServerKeys.CONFIG, configKey, configType, defaultValue],
    async () => {
      const result = await getSystemConfigurations({
        configKey,
        configType,
        defaultValue,
      });
      return result.data?.sysConfig || defaultValue;
    },
    {
      staleTime: 2 * 1000, // 2 sec
    }
  );

  return queryOptions;
}
