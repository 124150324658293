import './ConsumptionChart.less';

import React from 'react';

import { ChartType, MeasureType } from '../ConsumptionBody';
import { getClustersConsumption } from 'utils/cluster';
import DetailedUnitsChart from 'components/DetailedUnitsChart/DetailedUnitsChart';

interface ConsumptionChartProps {
  filteredClusters?: ReturnType<typeof getClustersConsumption>;
  clusters?: any;
  chartType: ChartType;
  measureType?: MeasureType;
}

function ConsumptionChart({ filteredClusters, clusters, chartType, measureType = 'daily' }: ConsumptionChartProps) {
  if (!filteredClusters) {
    return null;
  }
  let tableData = filteredClusters?.[0]?.consumption?.flatMap((con) => con.days);

  return (
    <div className="ConsumptionChart" style={{}}>
      {tableData ? (
        <DetailedUnitsChart
          filteredClusters={clusters}
          chartType={chartType}
          height={400}
          grid={{
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
          }}
        />
      ) : null}
    </div>
  );
}

export default ConsumptionChart;
