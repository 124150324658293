import React, { useState } from 'react';
import { Modal, notification } from 'antd';
import { LibrariesLocale } from '@oyousryb/spark-dependency-libraries';
import { LibrariesResponseItem, LibraryType, LibraryInstallationStateEnum } from 'types/libraries.types';
import {
  useGetUploadFileURLFromGoogleStorage,
  useMutateClusterInstallPackages,
  useMutateInstallPackageGeneric,
  useSyncPackages,
} from 'hooks/libraries';

const defaultMavenRepo = 'https://repo1.maven.org/maven2' as const;
const defaultCranRepo = 'https://cloud.r-project.org' as const;

function useLibrariesLogic({ instanceID, locale }: { instanceID: string; locale: LibrariesLocale }) {
  const [packageFilter, setPackageFilter] = useState('');
  const [isAddLibraryModalOpen, _setIsAddLibraryModalOpen] = useState(false);
  const [isEditLibraryModalOpen, _setIsEditLibraryModalOpen] = useState(false);
  const [selectedPythonPackage, _setSelectedPythonPackage] = useState<LibrariesResponseItem | null>(null);

  //#region UI
  function handleOpenAddLibraryModal() {
    _setIsAddLibraryModalOpen(true);
  }
  const handleCloseAddLibraryModal = () => {
    _setIsAddLibraryModalOpen(false);
  };

  function handleOpenUninstallModal() {
    _setIsAddLibraryModalOpen(true);
  }
  const handleCloseUninstallModal = () => {
    _setIsAddLibraryModalOpen(false);
  };

  function handleOpenEdit({ pythonPackage }: { pythonPackage: LibrariesResponseItem }) {
    _setSelectedPythonPackage(pythonPackage);
    _setIsEditLibraryModalOpen(true);
  }
  const handleCloseEdit = () => {
    _setIsEditLibraryModalOpen(false);
    _setSelectedPythonPackage(null);
  };

  function getAnyPodInstallingOrUninstalling({
    analyticsState,
    loaderState,
    chidoriState,
    sparkState,
  }: {
    analyticsState: LibraryInstallationStateEnum;
    loaderState: LibraryInstallationStateEnum;
    chidoriState?: LibraryInstallationStateEnum | null;
    sparkState: LibraryInstallationStateEnum;
  }) {
    const isAnalyticsPodInstallingOrUninstalling = [
      LibraryInstallationStateEnum.installing,
      LibraryInstallationStateEnum.uninstalling,
    ].includes(analyticsState);
    const isLoaderPodInstallingOrUninstalling = [
      LibraryInstallationStateEnum.installing,
      LibraryInstallationStateEnum.uninstalling,
    ].includes(loaderState);
    const isChidoriPodInstallingOrUninstalling =
      !!chidoriState &&
      [LibraryInstallationStateEnum.installing, LibraryInstallationStateEnum.uninstalling].includes(chidoriState);
    const isSparkPodInstallingOrUninstalling = [
      LibraryInstallationStateEnum.installing,
      LibraryInstallationStateEnum.uninstalling,
    ].includes(sparkState);
    const isAnyPodInstallingOrUninstalling =
      isAnalyticsPodInstallingOrUninstalling ||
      isLoaderPodInstallingOrUninstalling ||
      isSparkPodInstallingOrUninstalling ||
      isChidoriPodInstallingOrUninstalling;
    return isAnyPodInstallingOrUninstalling;
  }
  //#endregion

  const { mutateAsync: _mutateClusterInstallPackages, isLoading: isMutateClusterInstallLoading } =
    useMutateClusterInstallPackages({
      instanceID,
    });

  const { mutateAsync: _mutateRefreshPythonPackages, isLoading: isRefreshPythonPackagesLoading } = useSyncPackages({
    instanceID,
    type: LibraryType.PYPI,
  });

  const {
    mutateAsync: mutateGetUploadFileURLFromGoogleStorage,
    // isLoading: isGetUploadFileURLFromGoogleStorageLoading,
  } = useGetUploadFileURLFromGoogleStorage();

  async function handleEditPackages(packagesToBeEdited: string) {
    try {
      const { data } = await _mutateClusterInstallPackages({
        type: LibraryType.PYPI,
        data: {
          packages: packagesToBeEdited,
        },
      });
      const actionMessage = locale.actionModal.edit;
      const messageTitle = locale.actionModal.title.replace(
        '{action}',
        actionMessage[0].toLocaleUpperCase() + actionMessage.slice(1)
      );
      notification.info({
        message: messageTitle,
        description: data.message,
      });
    } catch (error) {}
  }

  async function refreshPythonPackages() {
    try {
      const status = await _mutateRefreshPythonPackages();
      if (status === 200) {
        notification.success({
          message: 'Success',
          description: 'Python Packages Versions Syncronised',
        });
      }
    } catch (error) {}
  }

  const { mutateAsync: _mutateInstallPyPiPackage, isLoading: isInstallPyPiPackageLoading } =
    useMutateInstallPackageGeneric<LibraryType.PYPI>({ instanceID });
  const { mutateAsync: _mutateInstallMavenPackage, isLoading: isInstallMavenPackageLoading } =
    useMutateInstallPackageGeneric<LibraryType.MAVEN>({ instanceID });
  const { mutateAsync: _mutateInstallCRANPackage, isLoading: isInstallCranPackageLoading } =
    useMutateInstallPackageGeneric<LibraryType.CRAN>({ instanceID });
  const { mutateAsync: _mutateInstallFilePyPiPackage, isLoading: isInstallFilePackageLoading } =
    useMutateInstallPackageGeneric<LibraryType.FILE>({ instanceID });

  function _getAlreadyInstalledLibrary({
    packageName,
    installedLibraries,
    type,
    repo,
  }: {
    packageName: string;
    type: LibraryType;
    installedLibraries: LibrariesResponseItem[];
    repo?: string;
  }) {
    const shouldCheckRepository = [LibraryType.MAVEN, LibraryType.CRAN].includes(type);
    const alreadyInstalledPackagesNames = installedLibraries
      ?.filter((pkg) => {
        const isSameName = packageName.toLocaleLowerCase() === pkg.name.split('==')[0].toLocaleLowerCase();
        if (pkg.type === type && pkg.name) {
          if (shouldCheckRepository) {
            return isSameName && repo && pkg.sourceColumn?.includes(repo);
          }
          return isSameName;
        }
      })
      ?.map((pkg) => pkg.name.split('==')[0]);

    return alreadyInstalledPackagesNames;
  }

  async function installPyPi({
    newPackages,
    installedLibraries,
  }: {
    newPackages: string;
    installedLibraries: LibrariesResponseItem[];
  }) {
    const alreadyInstalledPackagesNames = _getAlreadyInstalledLibrary({
      packageName: newPackages,
      installedLibraries,
      type: LibraryType.PYPI,
    });

    async function proceedInstallation() {
      await _mutateInstallPyPiPackage({
        type: LibraryType.PYPI,
        data: {
          packages: newPackages,
        },
      });
    }

    if (alreadyInstalledPackagesNames && alreadyInstalledPackagesNames.length) {
      Modal.confirm({
        title: 'Installed Packages Detected',
        content: (
          <>
            Please note that <b>{alreadyInstalledPackagesNames.join(', ')}</b>{' '}
            {alreadyInstalledPackagesNames.length < 2 ? 'is' : 'are'} already installed, do you want to overwrite{' '}
            {alreadyInstalledPackagesNames.length < 2 ? 'it' : 'them'}?
          </>
        ),
        centered: true,
        width: 620,
        okText: 'Confirm',
        cancelText: 'Cancel',
        async onOk() {
          await proceedInstallation();
        },
      });
    } else {
      await proceedInstallation();
    }
  }

  async function installMaven({
    mavenCoordinates,
    mavenRepo,
    installedLibraries,
  }: {
    mavenCoordinates: string;
    mavenRepo?: string;
    installedLibraries: LibrariesResponseItem[];
  }) {
    const alreadyInstalledPackagesNames = _getAlreadyInstalledLibrary({
      packageName: mavenCoordinates,
      installedLibraries,
      type: LibraryType.MAVEN,
      repo: mavenRepo || defaultMavenRepo,
    });

    async function proceedInstallation() {
      await _mutateInstallMavenPackage({
        type: LibraryType.MAVEN,
        data: {
          coordinates: mavenCoordinates,
          ...(mavenRepo
            ? {
                repository: mavenRepo,
              }
            : {}),
        },
      });
    }

    if (alreadyInstalledPackagesNames && alreadyInstalledPackagesNames.length) {
      Modal.confirm({
        title: 'Installed Packages Detected',
        content: (
          <>
            Please note that <b>{alreadyInstalledPackagesNames.join(', ')}</b>{' '}
            {alreadyInstalledPackagesNames.length < 2 ? 'is' : 'are'} already installed, do you want to overwrite{' '}
            {alreadyInstalledPackagesNames.length < 2 ? 'it' : 'them'}?
          </>
        ),
        centered: true,
        width: 620,
        okText: 'Confirm',
        cancelText: 'Cancel',
        async onOk() {
          await proceedInstallation();
        },
      });
    } else {
      await proceedInstallation();
    }
  }

  async function installCRAN({
    cranPackage,
    cranRepository,
    installedLibraries,
  }: {
    cranPackage: string;
    cranRepository?: string;
    installedLibraries: LibrariesResponseItem[];
  }) {
    const alreadyInstalledPackagesNames = _getAlreadyInstalledLibrary({
      packageName: cranPackage,
      installedLibraries,
      type: LibraryType.CRAN,
      repo: cranRepository || defaultCranRepo,
    });

    async function proceedInstallation() {
      await _mutateInstallCRANPackage({
        type: LibraryType.CRAN,
        data: {
          package: cranPackage,
          repository: cranRepository,
        },
      });
    }

    if (alreadyInstalledPackagesNames && alreadyInstalledPackagesNames.length) {
      Modal.confirm({
        title: 'Installed Packages Detected',
        content: (
          <>
            Please note that <b>{alreadyInstalledPackagesNames.join(', ')}</b>{' '}
            {alreadyInstalledPackagesNames.length < 2 ? 'is' : 'are'} already installed, do you want to overwrite{' '}
            {alreadyInstalledPackagesNames.length < 2 ? 'it' : 'them'}?
          </>
        ),
        centered: true,
        width: 620,
        okText: 'Confirm',
        cancelText: 'Cancel',
        async onOk() {
          await proceedInstallation();
        },
      });
    } else {
      await proceedInstallation();
    }
  }

  async function installFile({
    fileName,
    installedLibraries,
  }: {
    fileName: string;
    installedLibraries: LibrariesResponseItem[];
  }) {
    const alreadyInstalledPackagesNames = _getAlreadyInstalledLibrary({
      packageName: fileName,
      installedLibraries,
      type: LibraryType.FILE,
    });

    async function proceedInstallation() {
      await _mutateInstallFilePyPiPackage({
        type: LibraryType.FILE,
        data: { file: fileName },
      });
    }

    if (alreadyInstalledPackagesNames && alreadyInstalledPackagesNames.length) {
      Modal.confirm({
        title: 'Installed Packages Detected',
        content: (
          <>
            Please note that <b>{alreadyInstalledPackagesNames.join(', ')}</b>{' '}
            {alreadyInstalledPackagesNames.length < 2 ? 'is' : 'are'} already installed, do you want to overwrite{' '}
            {alreadyInstalledPackagesNames.length < 2 ? 'it' : 'them'}?
          </>
        ),
        centered: true,
        width: 620,
        okText: 'Confirm',
        cancelText: 'Cancel',
        async onOk() {
          await proceedInstallation();
        },
      });
    } else {
      await proceedInstallation();
    }
  }

  async function uploadFileToGoogleStorageAndReturnURL({ fileName }: { fileName: string }) {
    const { data } = await mutateGetUploadFileURLFromGoogleStorage({
      instanceID,
      fileName,
    });
    return data.uploadURL;
  }

  return {
    /* ----------------------------------- UI ----------------------------------- */
    packageFilter,
    setPackageFilter,
    isAddLibraryModalOpen,
    isEditLibraryModalOpen,
    selectedPythonPackage,
    handleOpenAddLibraryModal,
    handleCloseAddLibraryModal,
    handleOpenUninstallModal,
    handleCloseUninstallModal,
    handleOpenEdit,
    handleCloseEdit,
    getAnyPodInstallingOrUninstalling,
    /* -------------------------------------------------------------------------- */
    /*                                    PyPi                                    */
    /* -------------------------------------------------------------------------- */
    installPyPi,
    isInstallPyPiPackageLoading,
    handleEditPackages,
    isMutateClusterInstallLoading, // Edit Version
    refreshPythonPackages,
    isRefreshPythonPackagesLoading,
    /* -------------------------------------------------------------------------- */
    /*                                    Maven                                   */
    /* -------------------------------------------------------------------------- */
    installMaven,
    isInstallMavenPackageLoading,
    /* -------------------------------------------------------------------------- */
    /*                                    CRAN                                    */
    /* -------------------------------------------------------------------------- */
    installCRAN,
    isInstallCranPackageLoading,
    /* -------------------------------------------------------------------------- */
    /*                                    File                                    */
    /* -------------------------------------------------------------------------- */
    uploadFileToGoogleStorageAndReturnURL,
    installFile,
    isInstallFilePackageLoading,
    /* -------------------------------------------------------------------------- */
    /*                             Delete / Uninstall                             */
    /* -------------------------------------------------------------------------- */
  };
}

export default useLibrariesLogic;
