import React from 'react';
import ServiceAutoScalingChart from 'components/ClusterDetails/ClusterDetailsBody/ScalingConfigurations/ServiceAutoScalingChart/ServiceAutoScalingChart';
import { clusterScalingNode } from 'types/cluster';

type AnalyticsChartProps = {
  instanceId: string;
  clusterScalingNodes: clusterScalingNode[];
  showQueries: boolean;
};
function AnalyticsChart({ instanceId, clusterScalingNodes, showQueries }: AnalyticsChartProps) {
  return (
    <ServiceAutoScalingChart
      chartService="analytics"
      instanceId={instanceId}
      clusterScalingNodes={clusterScalingNodes}
      showQueries={showQueries}
    />
  );
}

export default AnalyticsChart;
