import React from 'react';
import { Divider } from 'antd';
import AutoScalingControl from './AutoScalingControl/AutoScalingControl';
import AnalyticsChart from 'components/ClusterDetails/ClusterDetailsBody/ScalingConfigurations/AnalyticsChart/AnalyticsChart';
import LoaderChart from 'components/ClusterDetails/ClusterDetailsBody/ScalingConfigurations/LoaderChart/LoaderChart';
import { AutoScaleItem, clusterScalingNode } from 'types/cluster';
import './ScalingConfigurations.less';

function ScalingConfigurations({
  instanceID,
  isInstanceRunning,
  autoScaleStatus,
  analyticsClusterScalingNodes,
  loaderClusterScalingNodes,
  showAutoScaling,
  showLoader,
}: {
  instanceID: string;
  isInstanceRunning: boolean;
  autoScaleStatus: AutoScaleItem | null;
  analyticsClusterScalingNodes: clusterScalingNode[];
  loaderClusterScalingNodes: clusterScalingNode[];
  showAutoScaling: boolean;
  showLoader?: boolean;
}) {
  return (
    <div className="scaling-configurations__wrapper">
      {showAutoScaling && (
        <>
          <AutoScalingControl
            instanceID={instanceID}
            isInstanceRunning={isInstanceRunning}
            autoScaleStatus={autoScaleStatus}
          />
          <Divider />
        </>
      )}
      {/* <Title level={3}>
        <FormattedMessage id="clusterConfiguration.configurationsTab.scaling.autoScaling.autoScalingHistory" />
      </Title> */}

      <AnalyticsChart
        instanceId={instanceID}
        clusterScalingNodes={analyticsClusterScalingNodes}
        showQueries={showAutoScaling}
      />
      {showLoader && (
        <>
          <Divider />
          <LoaderChart instanceId={instanceID} clusterScalingNodes={loaderClusterScalingNodes} />
        </>
      )}
    </div>
  );
}

export default ScalingConfigurations;
