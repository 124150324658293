import React, { useLayoutEffect, useRef } from 'react';
import echarts from 'echarts/lib/echarts';
import useResizeObserver from 'use-resize-observer';
import { ChartType } from 'components/Consumption/ConsumptionBody/ConsumptionBody';

interface ReplicaData {
  powerUnit: number;
  replica: number;
}

interface ServiceData {
  [name: string]: Array<ReplicaData>;
}

interface IncortaDailyData {
  date: string;
  powerUnit: number;
  services: {
    [service: string]: ServiceData;
  };
}

interface SparkDailyData {
  date: string;
  powerUnit: number;
  types: {
    [type: string]: number;
  };
}

interface ConsumptionAgg {
  spark: {
    daily: SparkDailyData[];
  };
  incorta: {
    daily: IncortaDailyData[];
  };
}

interface ClusterData {
  id: string;
  name: string;
  consumptionAgg: ConsumptionAgg;
}

interface DetailedUnitsChartProps {
  filteredClusters: ClusterData[];
  chartType: ChartType;
  height?: number;
  grid?: any;
}

const DetailedUnitsChart: React.FC<DetailedUnitsChartProps> = ({ filteredClusters, chartType, height = 300, grid }) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const eChartInstance = useRef<echarts.ECharts | null>(null);

  useResizeObserver({
    ref: chartRef,
    onResize: () => {
      eChartInstance.current?.resize();
    },
  });

  // prettier-ignore
  function getShades(baseColor: string): string[] {
    switch (baseColor) {
      case '#4CAF50':
        return [ '#3d8c40', '#48a54b', '#275929', '#bfe3c1', '#1c3f1d', '#5ab75e', '#a6d8a8', '#a1cf96', '#213a1b', '#cbe4c5'];
      case '#2196f3':
        return [ '#0c83e1', '#074679', '#86c6f8', '#41a5f5', '#0c83e1', '#cbe6fc', '#053256', '#074679', '#1e95f3', '#a9d6fa'];
      case '#ff4d4f':
        return [ '#ffc8c9', '#5b0001', '#ff8081', '#a40002', '#ff3739', '#c80002', '#ffeded', '#ed0003', '#ff1215', '#370001'];
      case '#36cfc9':
        return [ '#208480', '#40d1cc', '#98e6e3', '#2ebfb9', '#d3f4f3', '#124947', '#5dd8d4', '#f0fbfb', '#196764', '#7bdfdb'];
      case '#9254de':
        return [ '#6e26c6', '#e2d1f6', '#4c1b89', '#9457df', '#5d20a8', '#ceb3f0', '#8039d9', '#3b156b', '#a776e4', '#bb94ea'];
      case '#FF5722':
        return [ '#ffbaa4', '#ff9e80', '#ffd5c8', '#ff825b', '#fff1ed', '#ff6737', '#ff4b12', '#801f00', '#c83000', '#ed3900'];
      case '#1677ff':
        return [ '#0057d1', '#006aff', '#0044a2', '#b9d6ff', '#2e85ff', '#e8f1ff', '#001d46', '#003074', '#5da0ff', '#8bbbff'];
      case '#faad14':
        return [ '#faa805', '#fbb833', '#cc8904', '#fee7bb', '#fff7e8', '#9f6b03', '#fcc860', '#fdd78d', '#442e01', '#714c02'];
      case '#fa541c':
        return [ '#fb8760', '#fa6533', '#f94306', '#711e03', '#9f2b04', '#fdccbb', '#feeee8', '#fcaa8e', '#cc3705', '#441202'];
      case '#fadb14':
        return [ '#fdee8d', '#ccb104', '#fffce8', '#fbe033', '#fef5bb', '#9f8a03', '#443b01', '#fad905', '#fce760', '#716202'];
      case '#3d29d1':
        return [ '#281b88', '#3e2ad5', '#edecfb', '#6151dd', '#110b3a', '#cac5f4', '#a79eec', '#8477e4', '#1c1361', '#3322ae'];
      default:
        return [];
    }
  }

  // Define distinct colors for each cluster
  const clusterColors: string[] = [
    '#4CAF50',
    '#2196f3',
    '#ff4d4f',
    '#36cfc9',
    '#9254de',
    '#FF5722',
    '#1677ff',
    '#faad14',
    '#fa541c',
    '#fadb14',
    '#3d29d1',
  ];

  useLayoutEffect(() => {
    if (chartRef.current) {
      eChartInstance.current = echarts.init(chartRef.current);
    }
  }, []);

  useLayoutEffect(() => {
    if (!filteredClusters || filteredClusters.length === 0 || !eChartInstance.current) return;

    const legendData = filteredClusters.map((cluster, index) => ({
      name: cluster.name,
      itemStyle: {
        color: clusterColors[index % clusterColors.length],
      },
    }));

    const xAxisLabels: string[] = [];
    const seriesData: Array<{
      name: string;
      type: string;
      barWidth: number;
      stack: string;
      data: number[];
      itemStyle: { color: string };
    }> = [];

    // Collect unique dates
    filteredClusters.forEach((cluster) => {
      const { spark, incorta } = cluster.consumptionAgg;
      spark.daily.forEach((day) => {
        if (!xAxisLabels.includes(day.date)) xAxisLabels.push(day.date);
      });
      incorta.daily.forEach((day) => {
        if (!xAxisLabels.includes(day.date)) xAxisLabels.push(day.date);
      });
    });

    xAxisLabels.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

    // Process data for each cluster
    filteredClusters.forEach((cluster, clusterIndex) => {
      const baseColor = clusterColors[clusterIndex % clusterColors.length];
      let seriesCount = 0;

      const shades = getShades(baseColor);
      // Process Spark data
      cluster.consumptionAgg.spark.daily.forEach((day) => {
        Object.entries(day.types).forEach(([type, powerUnit]) => {
          const seriesName = `${cluster.name} - Spark (${type})`;
          let series = seriesData.find((s) => s.name === seriesName);
          if (!series) {
            const seriesColor = shades[seriesCount % shades.length];
            seriesCount++;
            series = {
              name: seriesName,
              type: chartType,
              barWidth: 30,
              stack: cluster.name,
              data: Array(xAxisLabels.length).fill(0),
              itemStyle: { color: seriesColor },
            };
            seriesData.push(series);
          }
          const dateIndex = xAxisLabels.indexOf(day.date);
          if (dateIndex >= 0) {
            series.data[dateIndex] += powerUnit;
          }
        });
      });

      // Process Incorta data
      cluster.consumptionAgg.incorta.daily.forEach((day) => {
        Object.entries(day.services).forEach(([service, names]) => {
          Object.entries(names).forEach(([name, replicas]) => {
            replicas.forEach(({ powerUnit, replica }) => {
              const seriesName = `${cluster.name} - Incorta (${service}, ${name}, Replica ${replica})`;
              let series = seriesData.find((s) => s.name === seriesName);
              if (!series) {
                const seriesColor = shades[seriesCount % shades.length];
                seriesCount++;
                series = {
                  name: seriesName,
                  type: chartType,
                  barWidth: 30,
                  stack: cluster.name,
                  data: Array(xAxisLabels.length).fill(0),
                  itemStyle: { color: seriesColor },
                };
                seriesData.push(series);
              }
              const dateIndex = xAxisLabels.indexOf(day.date);
              if (dateIndex >= 0) {
                series.data[dateIndex] += powerUnit;
              }
            });
          });
        });
      });
    });

    const legendSeries = filteredClusters.map((cluster, index) => ({
      name: cluster.name,
      type: chartType,
      stack: cluster.name,
      data: [],
      itemStyle: {
        color: clusterColors[index % clusterColors.length],
      },
    }));

    eChartInstance.current.setOption({
      animation: true,
      tooltip: {
        trigger: 'item',
        formatter: (params: any) =>
          `<strong>${params.seriesName}</strong><br/>
          Power Unit: ${params.value.toFixed(5)}`,
      },

      legend: {
        orient: 'horizontal',
        top: 0,
        selectedMode: false, // Disable clicking on legend items
        left: 'center',
        data: legendData.map((item) => ({
          name: item.name,
          icon: 'rect',
          itemStyle: {
            color: clusterColors[legendData.findIndex((d) => d.name === item.name) % clusterColors.length],
          },
        })),
        textStyle: {
          color: '#848F99',
        },
        formatter: (name: string) => {
          // Only show the cluster name without the additional details
          return name.split(' - ')[0];
        },
      },

      grid: {
        left: '1%',
        right: '3%',
        bottom: '6%',
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        data: xAxisLabels,
        name: 'Date',
        axisLabel: { color: '#848F99', rotate: 25 },
        axisLine: { lineStyle: { color: '#848F99' } },
      },
      yAxis: {
        type: 'value',
        name: 'Power Units',
        splitLine: { lineStyle: { color: '#EEF0F2' } },
        axisLabel: { color: '#848F99' },
        axisLine: { lineStyle: { color: '#848F99' } },
      },
      dataZoom: [
        {
          handleIcon:
            'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
          handleSize: '80%',
          handleStyle: {
            color: '#fff',
            shadowBlur: 3,
            shadowColor: 'rgba(0, 0, 0, 0.25)',
            shadowOffsetX: 2,
            shadowOffsetY: 2,
          },
        },
      ],
      series: [...legendSeries, ...seriesData],
    });
  }, [filteredClusters, grid, chartType, clusterColors]);

  return (
    <div
      className="UnitsChart"
      style={{
        width: '100%',
        // @ts-ignore
        '--height': `${height}px`,
      }}
    >
      <div className="UnitsChart__instance" ref={chartRef}></div>
    </div>
  );
};

export default DetailedUnitsChart;
