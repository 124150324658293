import Icon from '@ant-design/icons';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { endOfMonth, startOfMonth } from 'date-fns/esm';
import MainCard from '../MainCard/MainCard';
import { Instance } from 'types/cluster';
import { ReactComponent as ConsumedIcon } from 'images/consumed-icon.svg';
import { getClustersConsumption, getConsumptionTableDate } from 'utils/cluster';
import { sum } from 'utils';
import UnitsChart from 'components/UnitsChart/UnitsChart';
import BucketUnitsChart from 'components/BucketUnitsChart/BucketUnitsChart';

interface ClusterSizeCardProps {
  instance: Instance;
}

function ConsumedUnitsCard({ instance }: ClusterSizeCardProps) {
  let history = useHistory();

  let tableDate = useMemo(() => getConsumptionTableDate(instance.consumptionAgg, new Date().toString()), [instance]);
  let mothUnits = sum(tableDate, (d) => d.total);

  let maxIPUs = instance.maxIPUs
    ? (Number(JSON.parse(instance.maxIPUs).maxIncortaIPU) + Number(JSON.parse(instance.maxIPUs).maxSparkIPU)) / 30
    : null;
  let threshold = maxIPUs || Number((instance.analyticsSize.ipu + instance.loaderSize.ipu) / 30).toFixed(3);
  let consumption = getClustersConsumption(
    [
      {
        id: instance.id,
        name: instance.name,
        consumptionAgg: instance.consumptionAgg,
        historicalAgg: instance.historicalBucketMetrics,
      },
    ],
    {
      start: startOfMonth(new Date()),
      end: endOfMonth(new Date()),
    }
  );

  let historicalBucketMetrics = [
    {
      id: instance.id,
      name: instance.name,
      historicalBucketMetrics: consumption[0].historicalAgg,
    },
  ];

  return (
    <MainCard
      icon={<Icon component={ConsumedIcon} />}
      title={
        <>
          Consumed Incorta Power Units <span style={{ color: '#393E41' }}>(Current Month)</span>
        </>
      }
      subtitle={`${mothUnits} IPU`}
      footerText={'Consumption Details'}
      onFooterClick={() => {
        history.push({
          pathname: '/consumption',
          state: { clusterId: instance.id, clusterName: instance.name },
        });
      }}
    >
      <UnitsChart
        chartType="column"
        filteredClusters={consumption}
        height={300}
        grid={undefined}
        threshold={threshold}
      />

      <MainCard
        icon={<Icon component={ConsumedIcon} />}
        title={
          <>
            Historical Bucket Storage <span style={{ color: '#393E41' }}>(Current Month)</span>
          </>
        }
        subtitle={`${
          Number(instance.consumedData ?? 0) > 1000
            ? `${((Number(instance.consumedData) ?? 0) / 1000).toFixed(2)} TB`
            : `${Number(instance.consumedData).toFixed(3)} GB`
        }`}
        footerText={''}
        hideFooter={true}
      >
        <BucketUnitsChart
          chartType="column"
          buckets={historicalBucketMetrics}
          height={300}
          grid={undefined}
          threshold={0.0001}
        />
      </MainCard>
    </MainCard>
  );
}

export default ConsumedUnitsCard;
