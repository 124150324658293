import { LibrariesLocale } from '@oyousryb/spark-dependency-libraries';
export const en: LibrariesLocale = {
  general: {
    clusterMustBeConnectedToChange: 'Your cluster must be connected to apply this change',
  },
  title: 'Libraries',
  displayVersions: 'Sync Versions',
  installNewLibraryButton: 'Install new library',
  unInstallLibrariesButton: 'Uninstall Selected',
  filterLibraryPlaceholder: 'Filter Libraries',
  selectedCount: '{count} selected',
  librariesTable: {
    columnLabels: {
      name: 'Library',
      version: 'Version',
      type: 'Type',
      source: 'Source',
      status: 'Status',
      analyticsState: 'Analytics State',
      loaderState: 'Loader State',
      chidoriState: 'Chidori State',
    },
  },
  statusTags: {
    tooltip: {
      analytics: 'Analytics',
      loader: 'Loader',
      spark: 'Spark',
      chidori: 'Chidori',
    },
    status: {
      installing: 'Installing...',
      installed: 'Installed ✓',
      failed: 'Failed ✗',
      uninstalling: 'Uninstalling...',
      uninstalled: 'Uninstalled ✓',
    },
    viewDetails: 'View Details →',
    errorDetailsModal: {
      title: ' Error Details',
      serviceNames: {
        analytics: 'Analytics Service {number}',
        loader: 'Loader Service {number}',
      },
      copySuccessful: 'Error details copied successfully!',
    },
  },
  actionModal: {
    reinstall: 'Reinstall',
    edit: 'Edit',
    editVersion: 'Edit Version',
    uninstall: 'Uninstall',
    userCantUpdate: 'You need higher permission',
    anotherInstallingLibrary: 'There is another library installing, please wait for it',
    anotherUninstallingLibrary: 'There is another library uninstalling, please wait for it',
    title: '{action} Library',
    confirm: 'Are you sure you want to {action} {packageName} of version {packageVersion}?',
    confirmWithNoVersion: 'Are you sure you want to {action} {packageName}?',
    titleUninstallLibraries: 'Uninstall Libraries',
    confirmMultipleUninstall: 'Are you sure you want to uninstall the following libraries?',
    okText: 'Confirm',
    cancelText: 'Cancel',
    libraryToBeInstalled: 'Library will be reinstalled',
    deleteSuccess: 'Library successfully uninstalled',
    multipleDeleteSuccess: 'Libraries successfully uninstalled',
  },
  addPackageModal: {
    title: 'Install Package',
    install: 'Install',
    cancel: 'Cancel',
    librarySource: 'Library Source:',
    radios: {
      pypi: 'PyPI',
      organization: 'Organization',
      gcs: 'File Path / GCS',
      maven: 'Maven',
      cran: 'CRAN',
      fileLoader: 'File Uploader',
    },
    placeholder: 'Type and press Space eg., numpy==1.3',
    hint: 'Only packages that are compatible with pip.',
    confirmModal: {
      title: 'Installed Packages Detected',
      content: 'Please note that {packages} {plural1} already installed, do you want to overwrite {plural2}',
      singular1: 'is',
      plural1: 'are',
      singular2: 'it',
      plural2: 'them',
    },
    tab: {
      pypi: {
        label: 'PyPI Package(s)',
        required: 'Add at least one PyPI Package',
      },
      maven: {
        coordinatesField: 'Coordinates',
        coordinatesFieldRequired: 'Please add Maven Coordinates',
        coordinatesFieldPlaceholder: 'Maven Coordinates (com.yourcompany:spark-csv_2.10:1.0.0)',
        repositoryField: 'Repository',
        repositoryFieldTooltip:
          'The Maven repository serves as the source for installing the Maven package. If not specified, the default Maven repo will be used.',
      },
      cran: {
        packageField: 'Package',
        packageFieldRequired: 'Please add CRAN Package',
        repositoryField: 'Repository',
        repositoryFieldTooltip:
          'The CRAN repository serves as the source for installing the CRAN package. If not specified, the default CRAN repo will be used.',
      },
      file: {
        label: 'File',
        required: 'Please upload a supported file',
        unsupportedFile: 'You can only upload Py, JAR, WHL, or Egg files!',
      },
    },
  },
  editModal: {
    edit: 'Edit',
    title: 'Package Version',
    hint: 'Leave it empty if you want to install the latest version',
    errorLabel: 'Please enter a valid pacakge version e.g. 1, 1.0 or 1.0.0 or 1.0.0.0',
    reinstall: 'Reinstall',
    editVersion: 'Edit Version',
    delete: 'Delete',
  },
  actionNotSupportedInCurrentPlan: {
    message: 'Libraries platform action is disabled for the current platform',
  },
};
