import './BucketUnitsChart.less';

import React, { useLayoutEffect, useRef } from 'react';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/component/markLine';
import useResizeObserver from 'use-resize-observer';
import { ChartType, MeasureType } from 'components/Consumption/ConsumptionBody/ConsumptionBody';

interface HistoricalBucketMetric {
  id: number;
  instanceID: string;
  bucketSizeInGB: any;
  createdAt: string;
  updatedAt: string;
}

interface BucketData {
  id: string;
  name: string;
  historicalBucketMetrics: HistoricalBucketMetric[];
}

interface BucketUnitsChartProps {
  buckets: BucketData[];
  chartType: ChartType;
  measureType?: MeasureType;
  height?: number;
  grid: any;
  threshold: any;
}

function BucketUnitsChart({
  buckets,
  chartType,
  height = 300,
  grid,
  threshold,
  measureType = 'daily',
}: BucketUnitsChartProps) {
  let chartRef = useRef<HTMLDivElement>(null);
  let eChartInstance = useRef<echarts.ECharts | null>(null);

  useResizeObserver<HTMLDivElement>({
    ref: chartRef,
    onResize: () => {
      eChartInstance.current?.resize();
    },
  });

  useLayoutEffect(() => {
    eChartInstance.current = echarts.init(chartRef.current!);
  }, []);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = date.toLocaleString('en-US', { month: 'short' });
    return `${day} ${month}`;
  };

  useLayoutEffect(() => {
    if (!buckets || buckets.length === 0) return;

    // Process data for the chart
    const seriesData: any[] = [];
    const allDates = new Set<string>();

    // Collect all dates and prepare series data
    buckets.forEach((bucket) => {
      const metrics = bucket.historicalBucketMetrics || [];

      // Sort metrics by date
      metrics.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());

      // Add dates to the set
      metrics.forEach((metric) => {
        allDates.add(formatDate(metric.createdAt));
      });

      // Prepare series data
      const bucketData = metrics.map((metric) => ({
        name: formatDate(metric.createdAt),
        value: parseFloat((metric.bucketSizeInGB / 1000).toFixed(5)),
        updatedAt: formatDate(metric.updatedAt),
      }));

      seriesData.push({
        id: bucket.id,
        name: bucket.name || bucket.id,
        data: bucketData,
      });
    });

    // Sort dates for x-axis
    const sortedDates = Array.from(allDates);
    const maxDataValue = Math.max(
      ...seriesData.flatMap((series) =>
        series.data.map((point: any) => (typeof point === 'number' ? point : point?.value || 0))
      )
    );

    const yAxisMax = Number(Math.max(maxDataValue, threshold || 0) + 0.0001).toFixed(5);

    eChartInstance.current!.setOption({
      animation: false,
      color: [
        '#255888',
        '#D1481A',
        '#2189A4',
        '#FFA500',
        '#4D3B70',
        '#B35681',
        '#285F6C',
        '#DF2A4B',
        '#CD7565',
        '#850004',
        '#00A6D8',
        '#62BB9A',
      ],
      tooltip: {
        formatter(params, ticket, callback) {
          let _params = params as any;
          if (_params.componentType === 'markLine') {
            return `
            <div classname="tooltip">
              <div><strong>Threshold:</strong> ${threshold} TB</div>
            </div>
          `;
          }

          return `
            <div classname="tooltip">
              <div><strong>Bucket:</strong> ${_params.seriesName}</div>
              <div><strong>Date:</strong> ${_params.name}</div>
              <div><strong>Size:</strong> ${_params.value.toFixed(5)} TB</div>
              <div><strong>Last Updated:</strong> ${_params.data.updatedAt}</div>
            </div>
          `;
        },
        position: function (point: any, param, dom, rect, size: any) {
          const pos = point[0] - size.contentSize[0];

          if (pos > 0) {
            return [pos, '20%'];
          } else {
            return [point[0], '20%'];
          }
        },
      },

      dataZoom: [
        {
          handleIcon:
            'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
          handleSize: '80%',
          handleStyle: {
            color: '#fff',
            shadowBlur: 3,
            shadowColor: 'rgba(0, 0, 0, 0.25)',
            shadowOffsetX: 2,
            shadowOffsetY: 2,
          },
        },
      ],
      xAxis: {
        data: sortedDates,
        name: 'Date',
        ...axisStyles,
      },
      grid: {
        left: '1%',
        right: '3%',
        bottom: '6%',
        containLabel: true,
      },
      yAxis: {
        name: 'Storage (TB)',
        max: yAxisMax,
        splitLine: {
          lineStyle: {
            color: '#EEF0F2',
          },
        },
        ...axisStyles,
      },
      series: seriesData.map((series) => {
        return {
          name: series.name,
          data: series.data,
          type: chartType === 'column' ? 'bar' : 'line',
          areaStyle: {},
          emphasis: {
            focus: 'series',
          },
        };
      }),
    });
  }, [chartType, buckets, grid, measureType, threshold]);

  return (
    <div
      className="BucketUnitsChart"
      style={{
        width: '100%',
        // @ts-ignore
        '--height': `${height}px`,
      }}
    >
      <div className="BucketUnitsChart__instance" ref={chartRef}></div>
    </div>
  );
}

const axisStyles = {
  axisLine: {
    lineStyle: {
      color: '#848F99',
    },
  },
  axisLabel: {
    color: '#848F99',
  },
  axisTick: {
    lineStyle: {
      color: '#848F99',
    },
  },
};

export default BucketUnitsChart;
