export interface PasswordStrengthCondition {
  title: string;
  regex: RegExp;
  valid?: boolean;
}

export const PasswordStrengthConditions: PasswordStrengthCondition[] = [
  {
    title: 'Have 12 characters minimum ',
    regex: /^.{12,}$/g,
  },
  {
    title: 'Have 32 characters maximum ',
    regex: /^.{12,32}$/g,
  },
  {
    title: 'Have one lowercase character',
    regex: /(?=.*[a-z])/g,
  },
  {
    title: 'Have one uppercase character',
    regex: /(?=.*[A-Z])/g,
  },
  {
    title: 'Have one digit',
    regex: /(?=.*\d)/g,
  },
  {
    title: 'Have one special character',
    regex: /(?=.*[\W_])/g,
  },
  {
    title: 'Do not include spaces',
    regex: /(?=^\S*$)/g,
  },
];

export const pythonVersionRegex = /^\d+(\.\d+)?(\.\d+)?(\.\d+)?$/;

export const scheduledEventDateFormat = 'MMMM D, YYYY h:mm A';

export const seachableTablePageSizeOptions = [10, 20, 30];

export const SYSTEM_CONFIG_KEYS = {
  SCALING_MAX_REPLICAS: 'scalingMaxReplicas',
};
