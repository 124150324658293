import './SubClusterList.less';
import { Empty, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React from 'react';
import SubClusterState from './SubClusterState/SubClusterState';
import SelfManagedClusterType from './SelfManagedClusterType/SelfManagedClusterType';
import useSubCluster from './useSubCluster';
import { Instance, SelfManagedClusterTypes, SubCluster } from 'types/cluster';
import { getIU } from 'utils/cluster';
import SelfManagedClustersListActions from 'components/ClusterDetails/ClusterDetailsBody/SubCluster/SubClusterList/SelfManagedClustersListActions/SelfManagedClustersListActions';
import SelfManagedClustersListLinksActions from 'components/ClusterDetails/ClusterDetailsBody/SubCluster/SubClusterList/SelfManagedClustersListLinksActions/SelfManagedClustersListLinksActions';

interface SubClusterListProps {
  instance: Instance;
  createButtonUi?: React.ReactNode;
  minimum?: boolean;
}
function SubClusterList({ instance, createButtonUi = null, minimum = false }: SubClusterListProps) {
  const { getSubClusterState } = useSubCluster();

  const columns: ColumnsType<SubCluster> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render(value: any, subCluster: SubCluster) {
        return subCluster.nodes > 1 ? (
          <>
            {subCluster.name} <Tag className="cluster-more-info-tag">{subCluster.nodes} Replicas</Tag>
          </>
        ) : (
          subCluster.name
        );
      },
    },
    ...(!minimum
      ? [
          {
            title: 'IPU',
            dataIndex: 'ipu',
            key: 'ipu',
            render(value: any, subCluster: SubCluster) {
              return getIU(subCluster, subCluster.nodes);
            },
          },
          {
            title: 'Size',
            dataIndex: 'sizeDisplayName',
            key: 'sizeDisplayName',
          },
          {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',

            render(type: SelfManagedClusterTypes, subCluster: SubCluster) {
              return SelfManagedClusterType(subCluster);
            },

            // render: (type: SelfManagedClusterTypes, subCluster: SubCluster) => <SelfManagedClusterType type={type} configs={''} />,
          },
        ]
      : []),
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render(_: any, subCluster: SubCluster) {
        const state = getSubClusterState(subCluster);
        return <SubClusterState state={state} />;
      },
    },
    ...(minimum
      ? [
          {
            title: 'Size',
            dataIndex: 'sizeDisplayName',
            key: 'sizeDisplayName',
          },
        ]
      : []),
    {
      title: !minimum ? 'Endpoint' : 'URL',
      key: !minimum ? 'endpoint' : 'incorta',
      render: (_, subCluster) => {
        return <SelfManagedClustersListLinksActions subCluster={subCluster} instance={instance} />;
      },
    },
    ...(!minimum
      ? [
          {
            title: '',
            key: 'actions',
            render: (_: any, subCluster: SubCluster) => {
              return <SelfManagedClustersListActions subCluster={subCluster} instance={instance} />;
            },
          },
        ]
      : []),
  ];

  return (
    <div className="SubClusterList">
      <Table
        size="small"
        className="SubClusterList__table"
        pagination={false}
        columns={columns}
        dataSource={instance.subClusters}
        rowKey="id"
        locale={{
          emptyText: (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Self-Managed Clusters created yet.">
              {createButtonUi}
            </Empty>
          ),
        }}
      />
    </div>
  );
}

export default SubClusterList;
