import { LibrariesResponse } from '@oyousryb/spark-dependency-libraries/dist/components/Libraries/types/libraries.types';
import axios, { AxiosResponse } from 'axios';
import {
  LibrariesResponseItem,
  LibraryDataTypeMap,
  UnInstallLibraryResponse,
  UnInstallMultipleLibraryResponse,
  UploadFileResponse,
} from 'types/libraries.types';
import { API_URL } from 'utils/http';

export async function getLibraries({ instanceID }: { instanceID: string }): Promise<AxiosResponse<LibrariesResponse>> {
  return axios.get<LibrariesResponse>(`${API_URL}/instances/${instanceID}/libraries`);
}

export async function syncPackages({ instanceID, type }: { instanceID: string; type: string }) {
  return axios.post(`${API_URL}/instances/libraries/sync`, {
    instanceID,
    type,
  });
}

export async function installClusterPackages({ instanceID, ...rest }: { instanceID: string }) {
  return axios.put<{ message: string }>(`${API_URL}/instances/${instanceID}/libraries/install`, {
    ...rest,
  });
}

export async function installClusterPackageGeneric<T extends keyof LibraryDataTypeMap>({
  instanceID,
  type,
  data,
}: {
  instanceID: string;
  type: T;
  data: LibraryDataTypeMap[T];
}) {
  return axios.put<{ message: string }>(`${API_URL}/instances/${instanceID}/libraries/install`, {
    type,
    data,
  });
}

export async function uninstallSingleLibrary<T extends keyof LibraryDataTypeMap>({
  instanceID,
  type,
  data,
}: {
  instanceID: string;
  type: T;
  data: LibraryDataTypeMap[T];
}) {
  return axios.put<UnInstallLibraryResponse>(`${API_URL}/instances/${instanceID}/libraries/uninstall`, {
    type,
    data,
  });
}

export async function uninstallMultipleLibraries({
  instanceID,
  librariesToUninstall,
}: {
  instanceID: string;
  librariesToUninstall: LibrariesResponseItem[];
}) {
  return axios.put<UnInstallMultipleLibraryResponse>(
    `${API_URL}/instances/${instanceID}/libraries/uninstall-multiple`,
    librariesToUninstall
  );
}

export async function getUploadFileURLFromGoogleStorage({
  instanceID,
  fileName,
}: {
  instanceID: string;
  fileName: string;
}) {
  return axios.put<UploadFileResponse>(`${API_URL}/instances/${instanceID}/libraries/upload`, {
    clientOrigin: window.location.origin, // Frontend Domain (Can be localhost)
    file: fileName,
    // organization: '',
  });
}
