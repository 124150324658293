import React from 'react';
import './LibrariesContainer.less';
import { Libraries, LibraryType } from '@oyousryb/spark-dependency-libraries';
import axios from 'axios';
import { RcFile } from 'antd/lib/upload';
import message from 'antd/es/message';
import useLibrariesLogic from './useLibrariesLogic';
import { en as locale } from './locale/en';
import {
  useLibraries,
  useMutateInstallPackageGeneric,
  useUninstallLibrary,
  useUninstallMultipleLibrary,
  useMutateClusterInstallPackages,
  useSyncPackages,
} from 'hooks/libraries';

function LibrariesContainer({
  instanceID,
  isClusterRunning,
  hasClusterChidoriEnabled,
  canUpdate,
  isLibrariesPlatformActionSupported,
}: {
  instanceID: string;
  isClusterRunning: boolean;
  hasClusterChidoriEnabled: boolean;
  canUpdate: boolean;
  isLibrariesPlatformActionSupported: boolean;
}) {
  const { data: installedLibraries, isLoading: isLibrariesLoading } = useLibraries({
    instanceID,
    locale,
  });

  const { packageFilter, handleEditPackages, uploadFileToGoogleStorageAndReturnURL } = useLibrariesLogic({
    instanceID,
    locale,
  });

  const { mutateAsync: _mutateInstallPyPiPackage, isLoading: isInstallPyPiPackageLoading } =
    useMutateInstallPackageGeneric<LibraryType.PYPI>({ instanceID });
  const { mutateAsync: _mutateInstallMavenPackage, isLoading: isInstallMavenPackageLoading } =
    useMutateInstallPackageGeneric<LibraryType.MAVEN>({ instanceID });
  const { mutateAsync: _mutateInstallCRANPackage, isLoading: isInstallCranPackageLoading } =
    useMutateInstallPackageGeneric<LibraryType.CRAN>({ instanceID });
  const { mutateAsync: _mutateInstallFilePyPiPackage, isLoading: isInstallFilePackageLoading } =
    useMutateInstallPackageGeneric<LibraryType.FILE>({ instanceID });

  const { mutateAsync: mutateClusterInstallPackages, isLoading: isMutateClusterInstallLoading } =
    useMutateClusterInstallPackages({
      instanceID,
    });

  const { mutateAsync: _mutateRefreshPythonPackages, isLoading: isRefreshPythonPackagesLoading } = useSyncPackages({
    instanceID,
    type: LibraryType.PYPI,
  });

  const { mutateAsync: mutateUnInstallPyPi } = useUninstallLibrary<LibraryType.PYPI>({
    instanceID,
  });
  const { mutateAsync: mutateUnInstallMaven } = useUninstallLibrary<LibraryType.MAVEN>({
    instanceID,
  });
  const { mutateAsync: mutateUnInstallCRAN } = useUninstallLibrary<LibraryType.CRAN>({
    instanceID,
  });
  const { mutateAsync: mutateUnInstallFile } = useUninstallLibrary<LibraryType.FILE>({ instanceID });
  const { mutateAsync: mutateUninstallMultipleLibraries } = useUninstallMultipleLibrary({ instanceID });

  async function proceedPyPiInstallation({ packages }: { packages: string }) {
    await _mutateInstallPyPiPackage({
      type: LibraryType.PYPI,
      data: {
        packages: packages,
      },
    });
  }
  async function proceedMavenInstallation({
    mavenCoordinates,
    mavenRepo,
  }: {
    mavenCoordinates: string;
    mavenRepo?: string;
  }) {
    await _mutateInstallMavenPackage({
      type: LibraryType.MAVEN,
      data: {
        coordinates: mavenCoordinates,
        ...(mavenRepo
          ? {
              repository: mavenRepo,
            }
          : {}),
      },
    });
  }
  async function proceedCranInstallation({
    cranPackage,
    cranRepository,
  }: {
    cranPackage: string;
    cranRepository?: string;
  }) {
    await _mutateInstallCRANPackage({
      type: LibraryType.CRAN,
      data: {
        package: cranPackage,
        repository: cranRepository,
      },
    });
  }
  async function proceedFileInstallation({ fileName }: { fileName: string }) {
    await _mutateInstallFilePyPiPackage({
      type: LibraryType.FILE,
      data: { file: fileName },
    });
  }

  async function uploadFile({
    file,
    continueOnUploadSuccess,
  }: {
    file: RcFile;
    continueOnUploadSuccess: (body: any, xhr: XMLHttpRequest) => void;
  }) {
    const uploadURL = await uploadFileToGoogleStorageAndReturnURL({
      fileName: file.name,
    });
    // eslint-disable-next-line no-extra-boolean-cast
    const contentType = !!file.type ? file.type : 'application/octet-stream';
    const response = await axios.put(uploadURL, file, {
      headers: {
        'content-type': contentType,
        'Access-Control-Allow-Origin': '*',
      },
      onUploadProgress: (progressEvent) => {
        if (progressEvent.total) {
          const uploadProgress = progressEvent.loaded / progressEvent.total;
          if (uploadProgress === 1) {
            continueOnUploadSuccess?.(null, file as any);
          }
        }
      },
    });
    return response;
  }

  function showCopyErrorDetailsSuccessMessage() {
    message.success(locale.statusTags.errorDetailsModal.copySuccessful);
  }

  const dataSource = (installedLibraries || [])?.filter((installedPackage) =>
    installedPackage.name.toLocaleLowerCase().includes(packageFilter.toLocaleLowerCase())
  );

  return (
    <section className="libraries__container">
      <Libraries
        showTitle={false}
        locale={locale}
        canUpdate={canUpdate}
        hasClusterChidoriEnabled={hasClusterChidoriEnabled}
        isClusterRunning={isClusterRunning}
        isInstallPyPiPackageLoading={isInstallPyPiPackageLoading}
        isInstallMavenPackageLoading={isInstallMavenPackageLoading}
        isInstallCranPackageLoading={isInstallCranPackageLoading}
        isInstallFilePackageLoading={isInstallFilePackageLoading}
        isLibrariesLoading={isLibrariesLoading}
        isLibrariesPlatformActionSupported={isLibrariesPlatformActionSupported}
        isRefreshPythonPackagesLoading={isRefreshPythonPackagesLoading}
        isReinstallPackageLoading={isMutateClusterInstallLoading}
        installedLibraries={dataSource}
        handleEditPackages={handleEditPackages}
        uploadFile={uploadFile}
        refreshPyPiPackages={_mutateRefreshPythonPackages as any}
        proceedPyPiInstallation={proceedPyPiInstallation}
        proceedMavenInstallation={proceedMavenInstallation}
        proceedCranInstallation={proceedCranInstallation}
        proceedFileInstallation={proceedFileInstallation}
        proceedReinstallPackage={mutateClusterInstallPackages}
        proceedUninstallPyPi={mutateUnInstallPyPi}
        proceedUninstallCran={mutateUnInstallCRAN}
        proceedUninstallFile={mutateUnInstallFile}
        proceedUninstallMaven={mutateUnInstallMaven}
        unInstallMultipleLibraries={mutateUninstallMultipleLibraries}
        showCopyErrorDetailsSuccessMessage={showCopyErrorDetailsSuccessMessage}
      />
    </section>
  );
}

export default LibrariesContainer;
