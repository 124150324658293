import moment from 'moment';
import { ServiceName, clusterScalingNode } from 'types/cluster';
import { EChartsParam, echartsSeriesData } from 'utils/types';

export const humanReadableDateFormat = 'YYYY-MM-DD HH:mm:ss';

export enum AUTOSCALING_CHART_COLORS {
  INC_BLUE = '#3600fa',
  PORTAL_BLUE = '#365180',
  SLATE_GRAY = '#848f99',
  BLACK = '#000000',
  ORANGE = '#e15759',
  TANGERINE = '#f28e2b',
  LIGHT_GREEN = '#76b7b2',
  GREEN = '#59a14f',
  YELLOW = '#edc949',
  DARK_PINK = '#b07aa1',
  PINK = '#ff9da7',
  BROWN = '#9c755f',
}

export const autoScalingChartColorPalette = [
  AUTOSCALING_CHART_COLORS.TANGERINE,
  AUTOSCALING_CHART_COLORS.ORANGE,
  AUTOSCALING_CHART_COLORS.LIGHT_GREEN,
  AUTOSCALING_CHART_COLORS.GREEN,
  AUTOSCALING_CHART_COLORS.YELLOW,
  AUTOSCALING_CHART_COLORS.DARK_PINK,
  AUTOSCALING_CHART_COLORS.PINK,
  AUTOSCALING_CHART_COLORS.BROWN,
];

export function getInterval(largestData: number) {
  if (largestData <= 10) {
    return 1;
  } else if (largestData <= 20) {
    return 2;
  } else if (largestData <= 30) {
    return 3;
  } else if (largestData <= 40) {
    return 4;
  } else if (largestData <= 50) {
    return 5;
  } else if (largestData <= 100) {
    return 10;
  } else {
    return 50;
  }
}

export const MarkerHTML = ({ seriesColor }: { seriesColor: string }) => {
  return `<span style="display:inline-block; width:10px; height:10px; border-radius:50%; background-color:${seriesColor};"></span>`;
};

export const dataIcon = {
  analytics: 'circle',
  loader: 'rect',
};

export enum availableTimeFramesEnum {
  'all' = 'all',
  'last hour' = 'last hour',
  'last day' = 'last day',
  'last week' = 'last week',
  'last month' = 'last month',
}

export function startsWithNumber(text: string) {
  if (/^[0-9]/.test(text)) {
    return true;
  }
  return false;
}

export function tooltipFormatter({
  params,
  firstLineSavedText,
  totalQueriesText,
  rejectedQueriesText,
  thirdLineSavedText,
}: {
  params: EChartsParam[];
  firstLineSavedText: string;
  rejectedQueriesText: string;
  totalQueriesText: string;
  thirdLineSavedText: string;
}) {
  let tooltipContent = '';

  for (const key in params) {
    const param = params[key];
    const shouldAddHr = params.length > 1 && +key + 1 < params.length;

    if (startsWithNumber(param.seriesName)) {
      const queryType = param.seriesName.split('-')[1];
      const secondLineSavedText = queryType.includes('totalQueries') ? totalQueriesText : rejectedQueriesText;

      tooltipContent += queriesTooltipFormatter({
        param,
        firstLineSavedText,
        secondLineSavedText,
        thirdLineSavedText,
        shouldAddHr,
      });
    } else {
      tooltipContent += serviceReplicasTooltipFormatter({ param, shouldAddHr });
    }
  }

  return tooltipContent;
}

export function serviceReplicasTooltipFormatter({ param, shouldAddHr }: { param: EChartsParam; shouldAddHr: boolean }) {
  let tooltipContent = ``;

  const [createdAt, entries, scaleOption]: [string, number, string] = param.value;

  const createdAtDate = new Date(createdAt);
  const formattedcreatedAtDate = moment(createdAtDate).format(humanReadableDateFormat);

  const seriesColor = param.color;

  tooltipContent += `
    ${MarkerHTML({
      seriesColor,
    })} <label>Scale ${scaleOption}:</label> ${entries} <label>replicas</label><br/>
    <label>${formattedcreatedAtDate}</label><br />
    ${shouldAddHr ? '<hr/>' : ''}
            `;

  return tooltipContent;
}

export function queriesTooltipFormatter({
  param,
  firstLineSavedText,
  secondLineSavedText,
  thirdLineSavedText,
  shouldAddHr,
}: {
  param: EChartsParam;
  firstLineSavedText: string;
  secondLineSavedText: string;
  thirdLineSavedText: string;
  shouldAddHr: boolean;
}) {
  let tooltipContent = '';

  const replicaKey = param.componentIndex;
  const [startTimestamp, rejectedQueries]: [string, number] = param.value;

  const replicaShownNumber = (+replicaKey + 1).toString();
  const firstLine = `${firstLineSavedText} ${replicaShownNumber}`;
  const secondLine = `${secondLineSavedText} ${rejectedQueries}`;
  const thirdLine = `${thirdLineSavedText}  ${moment(startTimestamp).format(humanReadableDateFormat)}`;

  const seriesColor = param.color;

  tooltipContent += `
                ${MarkerHTML({ seriesColor })} ${firstLine}<br/>
                ${secondLine}<br/>
                ${thirdLine}<br/>
                ${shouldAddHr ? '<hr/>' : ''}
              `;

  return tooltipContent;
}

export function serviceReplicasLegendFormatter({ name, unit }: { name: string; unit: string }) {
  return name + unit;
}

export function queriesLegendFormatter({
  dataName,
  replica,
  unit,
}: {
  dataName: string;
  replica: number;
  unit: string;
}) {
  return dataName + (replica + 1) + unit;
}

export function addSupportingDataForStepDiagram({
  service,
  clusterScalingNodes,
}: {
  service: ServiceName;
  clusterScalingNodes: clusterScalingNode[];
}) {
  const newClusterScalingNodes = [clusterScalingNodes[0]];
  for (let i = 1; i < clusterScalingNodes.length; i++) {
    const prevNode = clusterScalingNodes[i - 1];
    const currentNode = clusterScalingNodes[i];
    let newNode: clusterScalingNode;
    const newNodeFirstPart = {
      ...prevNode,
      createdAt: new Date(new Date(currentNode.createdAt).getTime()).toISOString(),
    };

    newNode = {
      ...newNodeFirstPart,
      service,
    };

    newClusterScalingNodes.push(newNode!, currentNode);
  }
  return newClusterScalingNodes;
}

export function filterDataByTime({
  service,
  clusterScalingNodes,
  selectedTimeFrame,
}: {
  service: ServiceName;
  clusterScalingNodes: clusterScalingNode[];
  selectedTimeFrame: availableTimeFramesEnum;
}): echartsSeriesData {
  return clusterScalingNodes.map((d) => [d.createdAt, d.entries, d.scaleOption]);
}
